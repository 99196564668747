import { useSelector } from 'react-redux'
import Content from '../../Components/Content/Content'
import Headline from '../../Components/Headline/Headline'
import Features from '../../../../redux/features'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { useParams } from 'react-router-dom'
import toast, { TYPE } from '../../../../toast'
import Loading from '../../../UI/Loading/Loading'
import Routes from '../../../../redux/routes'
import Fingerprint from './Fingerprint/Fingerprint'
import Statistics from './Statistics/Statistics'
import History from '../../../UI/History/History'
import LockSwitch from './LockSwitch/LockSwitch'
import Settings from './Settings/Settings'
import FingerprintForAdmins from '../../../../types/FingerprintForAdmins'

const AnonymousView = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [fingerprint, setFingerprint] = useState<FingerprintForAdmins>(null)
  const { uuid } = useParams()
  const getAnonymousUsers = functional.use(Routes.GET_ANONYMOUS_USERS)

  useEffect(() => {
    const fetch = async () => {
      const fingerprint = await getAnonymousUsers({ fingerprintUUID: uuid })
      if (fingerprint) {
        setFingerprint(fingerprint)
      } else {
        toast(TYPE.ERROR, translation.generic.error.loading)
      }
    }

    if (!fingerprint) {
      fetch()
    }
  }, [getAnonymousUsers, fingerprint, uuid, translation])

  return (
    <div>
      <Headline>{translation.dashboard.user.anonymous.name}</Headline>
      <Content>
        {!!fingerprint && (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col justify-between items-center mt-5 gap-2 lg:flex-row">
              <div className="flex items-center flex-col gap-x-4 md:flex-row">
                <div className="flex flex-col">
                  <div className="text-xl text-center lg:text-left font-semibold leading-6 text-gray-900">{translation.dashboard.user.anonymous.name}</div>
                  {!!fingerprint.possibleMail && (
                    <div className="text-sm text-center lg:text-left text-gray-600">
                      {translation.dashboard.user.anonymous.possibleMail}
                      {fingerprint.possibleMail}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap gap-1 items-center justify-center mt-2 md:mt-0 md:flex-nowrap">
                <LockSwitch data={fingerprint} dataCallback={setFingerprint} />
                <Settings fingerprintUUID={fingerprint.uuid} />
              </div>
            </div>
            <Statistics fingerprint={fingerprint} />
            <History fingerprintUUID={fingerprint.uuid} hideUserName />
            <Fingerprint fingerprint={fingerprint} />
          </div>
        )}
        {!fingerprint && <Loading loading={fingerprint} />}
      </Content>
    </div>
  )
}

export default AnonymousView
