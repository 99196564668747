import { EnvelopeIcon } from '@heroicons/react/24/solid'
import User from '../../../types/User'
import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import Modal from '../../UI/Modal/Modal'
import Input from '../../UI/Input/Input'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import toast, { TYPE } from '../../../toast'
import ModalButton from './ModalButton'

type Props = {
  user: User
}

const Mail: React.FC<Props> = ({ user }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [show, setShow] = useState(false)
  const requestNewMail = functional.use(Routes.REQUEST_NEW_MAIL)
  const [mail, setMail] = useState(user.account.identifier)
  const [confirm, setConfirm] = useState(false)

  const requestChange = async () => {
    const result = await requestNewMail({ mail })
    if (result) {
      setConfirm(true)
    } else {
      toast(TYPE.ERROR, translation.home.profile.mail.error)
    }
  }

  return (
    <>
      <ModalButton onClick={() => setShow(true)} text={translation.home.profile.mail.change}>
        <EnvelopeIcon className="w-5" />
      </ModalButton>
      <Modal confirmButtonText={translation.generic.save} show={show} onClose={() => setShow(false)} onConfirm={!confirm ? requestChange : null} title={translation.generic.mail}>
        {confirm && <div className="text-center">{translation.home.profile.mail.confirm}</div>}
        {!confirm && user && (
          <div className="flex flex-col gap-3">
            <Input type="email" required label={translation.generic.mail} onChange={setMail} value={mail} />
          </div>
        )}
      </Modal>
    </>
  )
}

export default Mail
