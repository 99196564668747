import { useSelector } from 'react-redux'
import Features from '../../../../../redux/features'
import Admin from '../../../../../types/Admin'
import { useState } from 'react'
import Input from '../../../../UI/Input/Input'
import Button from '../../../../UI/Button/Button'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import { UserGroupIcon } from '@heroicons/react/24/solid'
import Modal from '../../../../UI/Modal/Modal'

type Props = {
  add: (user: Admin) => void
}

const Create: React.FC<Props> = ({ add }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [data, setData] = useState<Admin>({ firstname: '', lastname: '', mail: '', password: '' })
  const adminFeature = crud.use(Features.ADMIN)
  const [show, setShow] = useState(false)

  const set = (key: string) => (value: string) => {
    setData({ ...data, [key]: value })
  }

  const create = async () => {
    const admin = await adminFeature.create(data)
    if (admin) {
      setData({ firstname: '', lastname: '', mail: '' })
      add(admin)
      setShow(false)
      toast(TYPE.SUCCESS, translation.dashboard.admins.create.success)
    } else {
      toast(TYPE.ERROR, translation.dashboard.admins.create.error)
    }
  }

  return (
    <div>
      <div className="mb-1 flex flex-col items-end w-full" onClick={() => setShow(true)}>
        <Button text={translation.dashboard.admins.create.headline} className="h-[30px]">
          <UserGroupIcon className="w-5 h-5" />
        </Button>
      </div>
      <Modal confirmButtonText={translation.generic.save} show={show} onClose={() => setShow(false)} onConfirm={create} title={translation.dashboard.admins.create.headline}>
        <div className="flex flex-col gap-3">
          <Input label={translation.generic.firstname} onChange={set('firstname')} value={data.firstname} required />
          <Input label={translation.generic.lastname} onChange={set('lastname')} value={data.lastname} required />
          <Input label={translation.generic.mail} onChange={set('mail')} value={data.mail} required />
          <Input type="password" label={translation.generic.password} onChange={set('password')} value={data.password} required autoComplete="new-password" />
        </div>
      </Modal>
    </div>
  )
}

export default Create
