import { useSelector } from 'react-redux'
import Headline from '../../Components/Headline'
import Content from '../../Components/Content'
import Features from '../../../../redux/features'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { useParams } from 'react-router-dom'
import toast, { TYPE } from '../../../../toast'
import Loading from '../../../UI/Loading/Loading'
import Routes from '../../../../redux/routes'
import StampCardType from '../../../../types/StampCard'
import StampCard from '../../../UI/StampCard/StampCard'
import { getBusinessName } from '../../../../utility'
import HistoryOfUser from '../../../UI/StampCard/HistoryOfUser/HistoryOfUser'
import props from '../../../../redux/props'
import { Level } from '../../../../types/global'
import Layout from '../../../Layout/Layout'
import CategoryLabel from '../../../UI/Category/Label/Label'
import Fingerprint from '../../../../types/Fingerprint'

const View = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const fingerprint: Fingerprint = useSelector((s) => s[props.FINGERPRINT])
  const [stampCard, setStampCard] = useState<StampCardType>(null)
  const getVisitedBusinessList = functional.use(Routes.GET_VISITED_BUSINESS_LIST)
  const { businessUUID } = useParams()

  useEffect(() => {
    const list = async () => {
      const business = await getVisitedBusinessList({ businessUUID, fingerprintUUID: fingerprint.uuid })
      if (business) {
        setStampCard(business)
      } else {
        toast(TYPE.ERROR, translation.generic.error.loading)
      }
    }
    if (!stampCard && fingerprint) {
      list()
    }
  }, [businessUUID, translation, stampCard, getVisitedBusinessList, fingerprint])

  return (
    <Layout authLevelRequired={[Level.DEFAULT]}>
      {stampCard && (
        <>
          <div className="px-5 pt-5">
            <CategoryLabel category={stampCard?.business?.category} />
          </div>
          <Headline className="!pt-2">{getBusinessName(stampCard.business)}</Headline>
          <Content className="w-full">
            <div className="mt-5">
              <StampCard businessUUID={businessUUID} redeemLater={false} />
            </div>
            <HistoryOfUser businessUUID={businessUUID} />
          </Content>
        </>
      )}
      <Loading loading={stampCard} />
    </Layout>
  )
}

export default View
