import { useSelector } from 'react-redux'
import Features from '../../../../../redux/features'
import Loading from '../../../../UI/Loading/Loading'
import { getPrettyDateTime } from '../../../../../utility'
import FingerprintForAdmins from '../../../../../types/FingerprintForAdmins'

type Props = {
  fingerprint: FingerprintForAdmins | false
}

const Fingerprint: React.FC<Props> = ({ fingerprint }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  return (
    <div className="pb-4">
      <div className="font-bold text-lg">{translation.dashboard.user.view.fingerprint.headline}</div>
      <Loading loading={fingerprint !== null} />
      {fingerprint === false && <div>{translation.dashboard.user.view.fingerprint.empty}</div>}
      {fingerprint && (
        <ul className="divide-y divide-gray-100 overflow-x-auto">
          <li key={fingerprint.uuid} className="flex flex-col justify-between pt-1">
            <div className="text-sm">
              {translation.generic.lastUsed}
              {getPrettyDateTime(new Date(fingerprint.lastUsed))}
            </div>
            <div className="text-gray-500 text-sm">
              {translation.dashboard.user.view.fingerprint.id}
              {fingerprint.uuid}
            </div>
          </li>
        </ul>
      )}
    </div>
  )
}

export default Fingerprint
