import styled from 'styled-components'

export const StyledHeadline = styled.div`
  position: absolute;
  left: 0px;
  background-color: ${({ theme }) => theme.color.hint.orange};
  width: 100%;
  box-sizing: inherit;
  padding: 0px 15px;
  z-index: 1;
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.subHeaderHeight};
  font-family: 'headline';
`
