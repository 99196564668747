import { useDispatch, useSelector } from 'react-redux'
import Button from '../../UI/Button/Button'
import Features from '../../../redux/features'
import { useEffect, useState } from 'react'
import Toggle from '../../UI/Toggle/Toggle'
import Loading from '../../UI/Loading/Loading'
import cookie from '../../helper/cookie'
import { setLocal } from '../../../redux/action/local'
import props from '../../../redux/props'

type Props = {
  back: () => void
  close: () => void
}

type SettingsType = {
  [key: string]: boolean
}

const Settings: React.FC<Props> = ({ back, close }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [settings, setSettings] = useState<SettingsType>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!settings) {
      const consent = cookie.consent.get()
      if (consent) {
        setSettings(consent)
      } else {
        const consent = cookie.consent.init(translation, true)
        setSettings(consent)
      }
    }
  }, [settings, translation])

  const isEnabled = (name: string) => {
    return settings[name] === true
  }

  const toggle = (name: string) => (checked: boolean) => {
    const newSettings = { ...settings }
    newSettings[name] = checked
    setSettings(newSettings)
  }

  const save = () => {
    cookie.consent.set(settings)
    dispatch(setLocal(props.COOKIE_CONSENT, settings))
    close()
  }

  return (
    <div className="flex flex-col h-full gap-2 w-full">
      <div onClick={back} className="mb-2 text-black text-xs font-bold underline mt-1">
        {translation.cookieConsent.settings.back}
      </div>
      {settings && (
        <>
          {translation.cookieConsent.cookies.map((cookie) => (
            <div key={cookie.type} className="collapse collapse-arrow bg-base-100">
              <input type="radio" className="min-h-0" name="cookie-consent-accordion" />
              <div className="collapse-title py-2 min-h-0">{cookie.type}</div>
              <div className="collapse-content">
                <div className="text-sm">{cookie.description}</div>
                <div className="mt-2">
                  {cookie.items.map((item) => (
                    <div key={item.name} className="border-t py-1">
                      <div className="text-sm font-bold">{item.name}</div>
                      <div className="text-sm my-1">{item.description}</div>
                      <Toggle onChange={toggle(item.name)} disabled={cookie.disabled ? cookie.disabled : false} checked={isEnabled(item.name)} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
          <Button className="btn-secondary text-white btn-sm w-full mt-2" text={translation.cookieConsent.settings.save} onClick={save} />
        </>
      )}
      <Loading loading={settings} />
    </div>
  )
}

export default Settings
