import Input from '../UI/Input/Input'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../toast'
import { useNavigate } from 'react-router-dom'
import features from '../../redux/features'
import Form from '../UI/Form/Form'
import Container from '../UI/Container/Container'
import Button from '../UI/Button/Button'
import Checkbox from '../UI/Checkbox/Checkbox'
import Routes from '../../redux/routes'
import { UserIcon, LockClosedIcon, EnvelopeIcon, CakeIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/solid'
import props from '../../redux/props'
import Fingerprint from '../../types/Fingerprint'
import Link from '../UI/Link/Link'

type RegisterData = {
  fingerprintUUID?: string
  identifier?: string
  firstname?: string
  lastname?: string
  birthday?: Date
  phone?: string
  password?: string
  passwordConfirmation?: string
}

const Register = () => {
  const translation = useSelector((s) => s[features.TRANSLATION])
  const fingerprint: Fingerprint = useSelector((s) => s[props.FINGERPRINT])
  const [data, setData] = useState<RegisterData>({})
  const register = functional.use(Routes.REGISTER)
  const navigate = useNavigate()

  const submit = async () => {
    if (data.password === data.passwordConfirmation) {
      const status = await register({ ...data, fingerprintUUID: fingerprint.uuid })
      if (status) {
        navigate('/confirm')
      } else {
        toast(TYPE.ERROR, translation.register.error)
      }
    } else {
      toast(TYPE.ERROR, translation.register.passwordsDontMatch)
    }
  }

  const set = (key: keyof RegisterData) => (value: string) => {
    setData({ ...data, [key]: value })
  }

  return (
    <Layout authLevelRequired={false}>
      <Container className="px-10 py-3">
        <div className="mt-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-7 text-2xl font-bold leading-9">{translation.register.headline}</h2>
            <h2 className="text-secondary">{translation.register.subHeadline}</h2>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <Form onSubmit={submit} className="flex flex-col gap-4">
              <Input
                label={translation.generic.mail}
                placeholder={translation.generic.mail}
                onChange={set('identifier')}
                value={data.identifier}
                required
                type="email"
                autoComplete="email"
                Icon={EnvelopeIcon}
              />
              <Input label={translation.generic.firstname} placeholder={translation.generic.firstname} onChange={set('firstname')} value={data.firstname} required Icon={UserIcon} />
              <Input label={translation.generic.lastname} placeholder={translation.generic.lastname} onChange={set('lastname')} value={data.lastname} required Icon={UserIcon} />
              <Input label={translation.generic.birthday} placeholder={translation.generic.birthday} onChange={set('birthday')} value={data.birthday} type="date" Icon={CakeIcon} />
              <Input label={translation.generic.phone} placeholder={translation.generic.phone} onChange={set('phone')} value={data.phone} required type="tel" Icon={DevicePhoneMobileIcon} />
              <Input
                label={translation.generic.password}
                placeholder={translation.generic.password}
                onChange={set('password')}
                value={data.password}
                required={true}
                type="password"
                autoComplete="new-password"
                Icon={LockClosedIcon}
              />
              <Input
                label={translation.register.passwordConfirmation}
                placeholder={translation.register.passwordConfirmation}
                onChange={set('passwordConfirmation')}
                value={data.passwordConfirmation}
                required={true}
                type="password"
                autoComplete="new-password"
                Icon={LockClosedIcon}
              />
              <Checkbox required label={translation.register.agreeToTerms} />
              <Button type="submit" className="mt-3 w-full" text={translation.register.cta} />
            </Form>
            <p className="mt-5 text-center text-sm text-gray-500">{translation.register.alreadyHasAnAccount} </p>
            <Link href="/login" className="text-center font-semibold leading-6 underline block">
              {translation.register.loginNow}
            </Link>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Register
