import { StyledHeader, Toggle, Navigation } from './Header.Styled'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import props from '../../../redux/features'
import { setLocal } from '../../../redux/action/local'
import Container from '../../UI/Container/Container'
import Logo from '../../UI/Logo/Logo'
import cookie from '../../helper/cookie'
import Button from '../../UI/Button/Button'
import { AdjustmentsHorizontalIcon, CreditCardIcon, UserIcon } from '@heroicons/react/24/solid'
import ToggleIcon from './ToggleIcon'
import BackButton from './BackButton'
import { Level } from '../../../types/global'

type Props = {
  transparent?: boolean
}

const Header: React.FC<Props> = ({ transparent = false }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const token = useSelector((s) => s[props.TOKEN])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [navigationType, setNavigationType] = useState(null)

  useEffect(() => {
    if (accountLink) {
      setNavigationType(accountLink.level)
    }
  }, [navigationType, token, accountLink, translation])

  const logout = () => {
    cookie.remove('token')
    dispatch(setLocal(props.ACCOUNT_LINK, null))
    dispatch(setLocal(props.TOKEN, null))
    navigate('/')
  }

  const go = (path: string) => () => {
    navigate(path)
    setOpen(false)
  }

  const getIcon = (iconName: string, large = false, selected = false) => {
    const sizeClass = large ? 'w-7 h-7' : 'w-5 h-5'
    const colorClass = selected ? 'text-[#004D80]' : 'text-black'

    switch (iconName) {
      case 'user':
        return <UserIcon className={[sizeClass, colorClass].join(' ')} />
      case 'stampcard':
        return <CreditCardIcon className={[sizeClass, colorClass].join(' ')} />
      case 'settings':
        return <AdjustmentsHorizontalIcon className={[sizeClass, colorClass].join(' ')} />
      default:
        return null
    }
  }

  const isActive = (path: string) => {
    const currentPath = window.location.pathname
    if (path === '/home/stampcards') {
      return currentPath === path || currentPath === '/home'
    }
    return currentPath === path
  }

  return (
    <StyledHeader transparent={transparent}>
      <div className="flex items-center justify-center h-full px-3">
        <BackButton clickedCallback={() => setOpen(false)} />
        <Logo />
        {!!navigationType && navigationType !== Level.DEFAULT && (
          <Toggle onClick={() => setOpen(!open)}>
            <ToggleIcon />
          </Toggle>
        )}
      </div>
      {open && (
        <Navigation className="pt-10 pb-10">
          <Container className="h-full relative">
            <div className="text-2xl font-bold md:flex">{translation.navigation.headline}</div>
            <div className="flex flex-col gap-2 mt-2">
              {!!navigationType && (
                <>
                  {translation.navigation[navigationType].map((item) => {
                    return (
                      <Button className="bg-gray-100 text-xs max-w-sm border-none !justify-start md:flex" key={item.label} onClick={go(item.path)} text={item.label}>
                        {item.icon ? getIcon(item.icon) : null}
                      </Button>
                    )
                  })}
                  {navigationType !== 'public' && (
                    <Button className="btn-error border-none text-white w-fit" onClick={logout}>
                      {translation.navigation.logout}
                    </Button>
                  )}
                </>
              )}
            </div>
          </Container>
        </Navigation>
      )}
      {navigationType && navigationType === Level.DEFAULT && (
        <div className="btm-nav bg-white shadow-lg top-shadow">
          {translation.navigation[navigationType].map((item) => {
            return (
              <button className={`text-xs`} key={item.label} onClick={go(item.path)}>
                {item.icon ? getIcon(item.icon, true, isActive(item.path)) : null}
              </button>
            )
          })}
        </div>
      )}
    </StyledHeader>
  )
}

export default Header
