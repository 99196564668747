import { CoreContent } from './Layout.Styled'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'
import Fingerprint from './Fingerprint/Fingerprint'
import { AuthLevelRequired } from '../../types/global'
import CookieConsent from './CookieConsent/CookieConsent'
import ScrollToTop from './ScrollToTop/ScrollToTop'

interface ILayout {
  children: React.ReactNode
  className?: string
  hideHeader?: boolean
  authLevelRequired?: AuthLevelRequired
  removeMarginTop?: boolean
  transparentHeader?: boolean
}

const Layout: React.FC<ILayout> = ({ children, className, hideHeader = false, authLevelRequired = [], removeMarginTop, transparentHeader }) => {
  return (
    <div className={className}>
      <ScrollToTop />
      <Secure authLevelRequired={authLevelRequired}>
        {!hideHeader && <Header transparent={transparentHeader} />}
        <CoreContent hideHeader={hideHeader} removeMarginTop={removeMarginTop}>
          <CookieConsent>
            <Fingerprint>{children}</Fingerprint>
          </CookieConsent>
        </CoreContent>
        <Footer />
      </Secure>
    </div>
  )
}

export default Layout
