import { useSelector } from 'react-redux'
import Content from '../../Components/Content/Content'
import Headline from '../../Components/Headline/Headline'
import Features from '../../../../redux/features'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import { useParams } from 'react-router-dom'
import toast, { TYPE } from '../../../../toast'
import Loading from '../../../UI/Loading/Loading'
import Statistics from './Statistics/Statistics'
import Avatar from '../../../UI/Avatar/Avatar'
import User from '../../../../types/User'
import Fingerprint from './Fingerprint/Fingerprint'
import { getPrettyDate } from '../../../../utility'
import History from '../../../UI/History/History'
import LockSwitch from './LockSwitch/LockSwitch'
import Settings from './Settings/Settings'

const View = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [user, setUser] = useState<User>(null)
  const userFeature = crud.use(Features.USER)
  const { uuid } = useParams()

  useEffect(() => {
    const get = async () => {
      const user = await userFeature.get({ uuid })

      if (user) {
        setUser(user)
      } else {
        toast(TYPE.ERROR, translation.generic.error.loading)
      }
    }
    if (!user) {
      get()
    }
  }, [user, userFeature, uuid, translation])

  return (
    <div>
      <Headline>{user ? `${user.firstname} ${user.lastname}` : ''}</Headline>
      <Content>
        {!!user && (
          <div className="flex flex-col gap-5">
            <div className="flex flex-col justify-between items-center mt-5 gap-2 lg:flex-row">
              <div className="flex items-center flex-col gap-x-4 md:flex-row">
                <Avatar url={user.imageURL} alt={`${user.firstname} ${user.lastname}`} />
                <div className="flex flex-col">
                  <div className="text-xl font-semibold leading-6 text-gray-900">{`${user.firstname} ${user.lastname}`}</div>
                  {!!user?.account?.identifier && <p className="mt-1 text-center truncate text-s leading-5 text-gray-500 md:text-left">{user.account.identifier}</p>}
                  {user.birthday && (
                    <p className="mt-1 text-center truncate text-s leading-5 text-gray-500 md:text-left">
                      {translation.generic.birthday}
                      {': '}
                      {getPrettyDate(new Date(user.birthday))}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap gap-1 items-center justify-center mt-2 md:mt-0 md:flex-nowrap">
                <LockSwitch data={user} dataCallback={setUser} />
                <Settings uuid={user.uuid} />
              </div>
            </div>
            <Statistics fingerprintUUID={user.fingerprint.uuid} />
            <History fingerprintUUID={user.fingerprint.uuid} hideUserName />
            <Fingerprint fingerprint={user.fingerprint} />
          </div>
        )}
        {!user && <Loading loading={user} />}
      </Content>
    </div>
  )
}

export default View
