import Business from '../../../../types/Business'
import { prettyLocation } from '../../../../utility'
import Avatar from '../../../UI/Avatar/Avatar'
import Link from '../../../UI/Link/Link'

type Props = {
  business: Business
}

const Item: React.FC<Props> = ({ business }) => {
  return (
    <li key={business.uuid} className="flex justify-between gap-x-6 py-5">
      <div className="flex min-w-0 gap-x-4 items-center">
        <Avatar className="w-14" url={business.logoURL} alt={business.name} />
        <div className="min-w-0 flex-auto">
          <Link href={`/dashboard/business/view/${business.uuid}`} className="text-sm font-semibold leading-6 text-gray-900 hover:underline">
            {business.name}
          </Link>
          <p className="truncate text-xs leading-5 text-gray-500">{prettyLocation(business.location)}</p>
          {!!business.category && <p className="truncate text-xs leading-5 text-gray-500">({business.category.name})</p>}
        </div>
      </div>
      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
        {business.online ? (
          <div className="mt-1 flex items-center gap-x-1.5">
            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
              <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
            </div>
            <p className="text-xs leading-5 text-gray-500">Online</p>
          </div>
        ) : (
          <div className="mt-1 flex items-center gap-x-1.5">
            <div className="flex-none rounded-full bg-red-500/20 p-1">
              <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
            </div>
            <p className="text-xs leading-5 text-gray-500">Offline</p>
          </div>
        )}
      </div>
    </li>
  )
}

export default Item
