import { useSelector } from 'react-redux'
import FileSelect from '../../../UI/FileSelect/FileSelect'
import Features from '../../../../redux/features'
import { ReactNode, useState } from 'react'
import Input from '../../../UI/Input/Input'
import Progress from '../../../UI/Progress/Progress'
import { crud, functional } from '@think-internet/zeus-frontend-package'
import { useNavigate } from 'react-router-dom'
import toast, { TYPE } from '../../../../toast'
import { s3Upload } from '@think-internet/zeus-frontend-package/fetch/fetch'
import Routes from '../../../../redux/routes'
import Modal from '../../../UI/Modal/Modal'
import Business from '../../../../types/Business'
import Autocomplete from '../../../UI/Autocomplete/Autocomplete'
import { prettyLocation } from '../../../../utility'
import Loading from '../../../UI/Loading/Loading'
import CategoryAutocomplete from './CategoryAutocomplete/CategoryAutocomplete'

type Props = {
  business?: Business
  callback?: (data: Business) => void
  children: ReactNode
}

const Upsert: React.FC<Props> = ({ business, children, callback }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [data, setData] = useState<Business>(business || {})
  const businessFeature = crud.use(Features.BUSINESS)
  const getPresignedUploadURL = functional.use(Routes.GET_PRESIGNED_UPLOAD_URL)
  const navigate = useNavigate()
  const [progress, setProgress] = useState(0)
  const [show, setShow] = useState(false)

  const set = (key: string) => (value: any) => {
    setData({ ...data, [key]: value })
  }

  const processImage = async (data: Business) => {
    if (!data.logo) return data
    const { presignedURL, key } = await getPresignedUploadURL({ type: 'BUSINESS_LOGO', meta: { type: data.logo.type, name: data.logo.name, size: data.logo.size } })
    const uploadResult = await s3Upload(presignedURL, data.logo, setProgress)
    if (!uploadResult) return false
    return { ...data, logoKey: key }
  }

  const save = async () => {
    const processedData = await processImage(data)

    if (processedData) {
      const result = await businessFeature.upsert(processedData)
      if (!!result) {
        toast(TYPE.SUCCESS, translation.dashboard.business.upsert.success)
        setShow(false)
        if (callback) {
          callback(result)
        } else {
          navigate(`/dashboard/business/view/${result.uuid}`)
        }
      } else {
        toast(TYPE.ERROR, translation.dashboard.business.upsert.error)
      }
    } else {
      toast(TYPE.ERROR, translation.dashboard.business.upsert.error)
    }
  }

  return (
    <>
      <div onClick={() => setShow(true)}>{children}</div>
      <Modal
        confirmButtonText={translation.generic.save}
        show={show}
        onClose={() => setShow(false)}
        onConfirm={save}
        title={data === null ? '' : data?.name || (business ? '' : translation.dashboard.business.upsert.headline)}
      >
        {!data && <Loading loading={data} />}
        {data && (
          <div className="flex flex-col gap-3">
            <Input required label={translation.dashboard.business.upsert.name} onChange={set('name')} value={data.name} />
            <Input label={translation.generic.phone} onChange={set('phone')} value={data.phone} />
            <Input label={translation.generic.mail} onChange={set('mail')} value={data.mail} />
            <CategoryAutocomplete initValue={data.category?.name} label={translation.dashboard.business.upsert.selectCategory} onChange={set('categoryUUID')} />
            <Autocomplete initValue={prettyLocation(data.location)} label={translation.generic.location} onChange={set('location')} />
            <Input label={translation.dashboard.business.upsert.metaPixelID} onChange={set('metaPixelID')} value={data.metaPixelID} />
            <FileSelect acceptedMimeTypes={['image/*']} label={translation.dashboard.business.upsert.selectLogo} onFile={set('logo')} />
            <Progress progress={progress} />
          </div>
        )}
      </Modal>
    </>
  )
}

export default Upsert
