import Input from '../UI/Input/Input'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../toast'
import features from '../../redux/features'
import Form from '../UI/Form/Form'
import Container from '../UI/Container/Container'
import Button from '../UI/Button/Button'
import Routes from '../../redux/routes'
import { useNavigate, useParams } from 'react-router-dom'
import { LockClosedIcon } from '@heroicons/react/24/solid'

const SetNewPassword = () => {
  const translation = useSelector((s) => s[features.TRANSLATION])
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const setNewPassword = functional.use(Routes.SET_NEW_PASSWORD)
  const navigate = useNavigate()
  const { resettoken } = useParams()

  const submit = async () => {
    if (password === passwordConfirmation) {
      const status = await setNewPassword({ resettoken, password })
      if (status) {
        navigate('/login')
        toast(TYPE.SUCCESS, translation.setNewPassword.success)
      } else {
        toast(TYPE.ERROR, translation.setNewPassword.error)
      }
    } else {
      toast(TYPE.ERROR, translation.setNewPassword.passwordsDontMatch)
    }
  }

  return (
    <Layout authLevelRequired={false}>
      <Container className="px-3 py-3">
        <div className="mt-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-7 text-2xl font-bold leading-9">{translation.setNewPassword.headline}</h2>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <Form className="space-y-4" onSubmit={submit}>
              <Input
                label={translation.generic.password}
                placeholder={translation.generic.password}
                onChange={setPassword}
                value={password}
                required
                type="password"
                autoComplete="new-password"
                Icon={LockClosedIcon}
              />
              <Input
                label={translation.setNewPassword.passwordConfirmation}
                placeholder={translation.setNewPassword.passwordConfirmation}
                onChange={setPasswordConfirmation}
                value={passwordConfirmation}
                required
                type="password"
                autoComplete="new-password"
                Icon={LockClosedIcon}
              />
              <Button type="submit" className="flex w-full btn-sm btn-primary h-9 text-white" text={translation.setNewPassword.cta} />
            </Form>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default SetNewPassword
