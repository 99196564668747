import { IBaseTheme, IComponentPackageStyling } from './theme.d'

const cyan = '#45BDFF'
const yellow = '#FBD87D'
const pink = '#FCC3C4'

const green = '#76D275'
const orange = '#FFB442'
const red = '#FF5B5B'
const lightblue = '#5BC1E1'

const blue = '#004D80'
const darkpink = '#D6708F'

const black0 = '#383838'
const black1 = '#656565'
const black2 = '#9E9E9Е'
const black3 = '#D4D4D4'
const black4 = '#F2F2F2'

const white = '#ffffff'

const theme: IBaseTheme = {
  font: {
    size: {
      sm: '16px',
      md: '18px',
      lg: '22px',
      xlg: '24px',
    },
  },
  color: {
    font: {
      primary: black0,
      secondary: white,
      hint: black3,
    },
    background: {
      primary: white,
      secondary: cyan,
    },
    border: {
      primary: blue,
      secondary: darkpink,
    },
    button: {
      primary: white,
      secondary: black0,
    },
    primary: {
      cyan,
      yellow,
      pink,
    },
    hint: {
      green,
      orange,
      red,
      lightblue,
    },
    accent: {
      blue,
      darkpink,
    },
    black: {
      black0,
      black1,
      black2,
      black3,
      black4,
    },
  },
  breakpoint: {
    sm: '640px',
    md: '768',
    lg: '1024px',
    xl: '1280px',
  },
  border: {
    radius: '5px',
  },
  shadow: '2px 2px 16px -1px rgba(0,0,0,0.48)',
  headerHeight: '50px',
  subHeaderHeight: '40px',
}

const adjustedComponentStyling: IComponentPackageStyling = {
  accordion: {
    accordion: ``,
    flex: ``,
    item: {
      item: ``,
      header: ``,
      text: ``,
      openicon: ``,
      body: ``,
    },
  },
  button: {
    button: `
    background-color: ${theme.color.button.primary};
    border-color: ${theme.color.border.primary};
    transition: all 0.25s ease;
    :hover {
      background-color: ${theme.color.button.secondary};
      border-color: transparent;
      color: ${theme.color.font.secondary};
    }
    `,
    anchor: ``,
  },
  checkbox: {
    checkbox: ``,
    flex: ``,
    checkedicon: ``,
    label: ``,
    styledcheckbox: ``,
  },
  container: {
    container: ``,
  },
  draganddrop: {
    draganddrop: `border: none; background-color: ${theme.color.background.primary}`,
    title: ``,
    hint: `color: ${theme.color.font.hint}; font-size: ${theme.font.size.sm}; font-family: 'thin'`,
    filelist: ``,
    filename: ``,
  },
  dropdown: {
    dropdown: `width: 100%;
    `,
    select: `
    border: none;
    border-radius: 0px;
    border-bottom: thin solid ${theme.color.border.primary};
    padding-left: 0px;
    `,
    option: ``,
    label: ``,
  },
  form: {
    form: ``,
  },
  headline: {
    headline: ``,
  },
  hero: {
    hero: ``,
  },
  input: {
    input: `
      border: none;
      border-radius: 0px;
      border-bottom: thin solid ${theme.color.border.primary};
      padding-left: 0px;
      transition: border-bottom 0.25s ease-in-out;
      &:active, &:focus {
        border-bottom: thin solid ${theme.color.border.primary};
      }
    `,
    wrapper: ``,
    label: ``,
  },
  link: {
    link: ``,
  },
  modal: {
    modal: ``,
    background: ``,
    core: ``,
    header: ``,
    title: `
    font-family: bold;
    `,
    close: ``,
    content: `
    `,
    footer: ``,
  },
  progressbar: {
    progressbar: ``,
    progress: ``,
    label: ``,
  },
  textarea: {
    textarea: `
    border: none;
    border-radius: 0px;
    border-bottom: thin solid ${theme.color.border.primary};
    padding-left: 0px;
    transition: border-bottom 0.25s ease-in-out;
    &:active, &:focus {
      border-bottom: thin solid ${theme.color.border.primary};
    }
    `,
    wrapper: ``,
    label: ``,
  },
  datepicker: {
    wrapper: ``,
    label: ``,
  },
}

const exportableTheme = { ...theme, components: adjustedComponentStyling }

export default exportableTheme
