import User from '../../../../../types/User'
import Avatar from '../../../../UI/Avatar/Avatar'
import placeholderImage from '../../../../../assets/image/profile/placeholder.png'
import Link from '../../../../UI/Link/Link'

type Props = {
  user: User
}

const Item: React.FC<Props> = ({ user }) => {
  return (
    <li key={user.uuid} className="flex justify-between gap-x-6 py-2">
      <div className="flex min-w-0 gap-x-4 items-center">
        <Avatar className="w-12" url={user.imageURL || placeholderImage} alt={`${user.firstname} ${user.lastname}`} />
        <div className="min-w-0 flex-auto">
          <Link href={`/dashboard/user/view/${user.uuid}`} className="text-sm font-semibold leading-6 text-gray-900 hover:underline">
            {user.firstname} {user.lastname}
          </Link>
          {!!user?.account?.identifier && <p className="truncate text-xs leading-5 text-gray-500">{user.account.identifier}</p>}
        </div>
      </div>
    </li>
  )
}

export default Item
