import { useSelector } from 'react-redux'
import Features from '../../../../../redux/features'
import { getPrettyDateTime } from '../../../../../utility'
import FingerprintForAdmins from '../../../../../types/FingerprintForAdmins'
import Link from '../../../../UI/Link/Link'

type Props = {
  fingerprint: FingerprintForAdmins
}

const Item: React.FC<Props> = ({ fingerprint }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  return (
    <li key={fingerprint.uuid} className="flex justify-between gap-x-6 py-1">
      <div className="flex min-w-0 gap-x-4 items-center">
        <div className="min-w-0 flex-auto">
          <Link href={`/dashboard/user/view-anonymous/${fingerprint.uuid}`} className="text-sm font-semibold leading-6 text-gray-900 hover:underline">
            {translation.dashboard.user.anonymous.name}
          </Link>
          <p className="truncate text-xs leading-5 text-gray-500">
            {translation.generic.lastUsed}
            {getPrettyDateTime(new Date(fingerprint.lastUsed))}
          </p>
        </div>
      </div>
    </li>
  )
}

export default Item
