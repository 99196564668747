import { crud } from '@think-internet/zeus-frontend-package'

const Features = {
  ...crud.Features,
  USER: 'USER',
  BUSINESS: 'BUSINESS',
  BUSINESS_CATEGORY: 'BUSINESS_CATEGORY',
  ADMIN: 'ADMIN',
  ANONYMOUS_MAIL: 'ANONYMOUS_MAIL',
}

export default Features
