import { getUUID } from '../../../utility'

type Props = {
  label?: string
  required?: boolean
}

const Checkbox: React.FC<Props> = ({ label, required = false }) => {
  const uuid = getUUID()
  return (
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">
        <input id={uuid} name={uuid} type="checkbox" required={required} className="w-4 h-4 rounded" />
      </div>
      <div className="text-sm leading-6">
        <label className="cursor-pointer" htmlFor={uuid}>
          {label}
        </label>
      </div>
    </div>
  )
}

export default Checkbox
