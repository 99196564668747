import starEyes from '../../../../assets/image/stampcard/party.png'
import { useSelector } from 'react-redux'
import Features from '../../../../redux/features'
import BountyLabel from '../../../UI/Bounty/Label'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import { useParams } from 'react-router-dom'
import toast, { TYPE } from '../../../../toast'
import LoginOrRegister from '../../../UI/LoginOrRegister/LoginOrRegister'
import Loading from '../../../UI/Loading/Loading'
import Layout from '../../../Layout/Layout'
import Container from '../../../UI/Container/Container'
import RedeemLaterHelp from './LaterHelp'
import Business from '../../../../types/Business'

const RedeemLater: React.FC<{}> = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const { businessUUID } = useParams()
  const [business, setBusiness] = useState<Business>({
    name: '',
  })
  const businessFeature = crud.use(Features.BUSINESS)

  useEffect(() => {
    const getBusiness = async () => {
      const business = await businessFeature.get({ uuid: businessUUID })

      if (business) {
        setBusiness(business)
      } else {
        toast(TYPE.ERROR, translation.visit.error)
      }
    }

    if (!business) {
      getBusiness()
    }
  }, [businessUUID, business, businessFeature, translation])

  return (
    <Layout authLevelRequired={false}>
      <Container className="pt-5">
        <Loading loading={business} />
        {business && (
          <>
            <div className="text-center mt-5">
              <img className="h-24 inline" src={starEyes} alt={business.name} />
            </div>
            <div className="text-center text-2xl font-bold mt-3">{`${translation.stampCard.redeemLater.title.prefix}${business.name}${translation.stampCard.redeemLater.title.suffix}`}</div>
            <div className="text-center mt-3">
              {translation.stampCard.redeemLater.bounty.prefix}
              <BountyLabel business={business} />
            </div>
            <LoginOrRegister />
            <RedeemLaterHelp />
          </>
        )}
      </Container>
    </Layout>
  )
}

export default RedeemLater
