type Props = {
  loading: any
  className?: string
}

const Loading: React.FC<Props> = ({ loading, className }) => {
  if (!!loading) return null
  return (
    <div className={`text-center py-3 ${className || ''}`}>
      <span className="loading loading-ring loading-lg"></span>
    </div>
  )
}

export default Loading
