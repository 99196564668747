import { Route, Routes } from 'react-router-dom'
import Layout from '../Layout/Layout'
import CategoriesList from './BusinessCategories/List/List'
import { Level } from '../../types/global'
import BusinessView from './Business/View/View'
import BusinessList from './Business/List/List'
import UserList from './User/List/List'
import UserView from './User/View/View'
import AnonymousView from './User/AnonymousView/AnonymousView'

const Dashboard = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN]}>
      <Routes>
        <Route path={'business'} element={<BusinessList />} />
        <Route path={'business/view/:uuid'} element={<BusinessView />} />
        <Route path={'business/categories'} element={<CategoriesList />} />
        <Route path={'user'} element={<UserList />} />
        <Route path={'user/view/:uuid'} element={<UserView />} />
        <Route path={'user/view-anonymous/:uuid'} element={<AnonymousView />} />
        <Route path={'*'} element={<BusinessList />} />
      </Routes>
    </Layout>
  )
}

export default Dashboard
