import { crud } from '@think-internet/zeus-frontend-package'
import Features from '../../../../redux/features'
import { useEffect, useState } from 'react'
import BusinessCategory from '../../../../types/BusinessCategory'
import Loading from '../../../UI/Loading/Loading'
import Content from '../../Components/Content/Content'
import Headline from '../../Components/Headline/Headline'
import { useSelector } from 'react-redux'
import Upsert from '../Upsert/Upsert'
import Button from '../../../UI/Button/Button'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import Item from './Item'

const List = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const categoryFeature = crud.use(Features.BUSINESS_CATEGORY)
  const [categories, setCategories] = useState(null)

  useEffect(() => {
    const fetch = async () => {
      const categories = await categoryFeature.list({})
      if (Array.isArray(categories)) {
        setCategories(categories)
      }
    }

    if (!categories) {
      fetch()
    }
  }, [categoryFeature, categories])

  const upsertLocalCategory = (category: BusinessCategory) => {
    const index = categories.findIndex((c) => c.uuid === category.uuid)

    if (index === -1) return setCategories([category, ...categories])
    const newCategories = [...categories]
    newCategories[index] = category
    setCategories(newCategories)
  }

  const remove = (uuid: string) => () => {
    const newCategories = [...categories].filter((c) => c.uuid !== uuid)
    setCategories(newCategories)
  }

  return (
    <div>
      <Headline>{translation.dashboard.businessCategory.headline}</Headline>
      <Content>
        <div className="flex justify-end flex-col items-center gap-3 mt-3 sm:flex-row">
          <Upsert callback={upsertLocalCategory}>
            <Button text={translation.dashboard.businessCategory.add} className="btn-sm">
              <PlusCircleIcon className="w-5 h-5" />
            </Button>
          </Upsert>
        </div>
        {!!categories && (
          <ul className="divide-y divide-gray-200">
            {categories.map((category: BusinessCategory) => (
              <Item key={category.uuid} category={category} updateCallback={upsertLocalCategory} removeCallback={remove(category.uuid)} />
            ))}
          </ul>
        )}
        <Loading loading={categories} />
      </Content>
    </div>
  )
}

export default List
