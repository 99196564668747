import Countdown, { TYPES } from './Countdown'

type Props = {
  start: number
  doneCallback?: () => void
}

const Minute: React.FC<Props> = ({ start, doneCallback }) => {
  return (
    <div className="flex gap-1 items-center">
      <div className="flex flex-col p-2 bg-white rounded-box items-center font-bold">
        <Countdown start={start - 1} type={TYPES.MINUTE} doneCallback={doneCallback} />
        min
      </div>
      <div className="flex flex-col p-2 bg-white rounded-box items-center font-bold">
        <Countdown start={59} type={TYPES.SECOND} infinite />
        sek
      </div>
    </div>
  )
}

export default Minute
