type Props = {
  url?: string
  alt?: string
  className?: string
}

const Avatar: React.FC<Props> = ({ url, alt = '', className = '' }) => {
  return (
    <div className={`avatar w-20 aspect-square ${className}`}>
      <div className="rounded-2xl bg-white">{url && <img src={url} alt={alt} />}</div>
    </div>
  )
}

export default Avatar
