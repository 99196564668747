import Business from '../../../../types/Business'
import React, { useEffect, useState } from 'react'
import Headline from '../../Components/Headline/Headline'
import { useSelector } from 'react-redux'
import features from '../../../../redux/features'
import Content from '../../Components/Content/Content'
import { crud } from '@think-internet/zeus-frontend-package'
import Features from '../../../../redux/features'
import Loading from '../../../UI/Loading/Loading'
import Upsert from '../Upsert/Upsert'
import Item from './Item'
import { PlusCircleIcon, TagIcon } from '@heroicons/react/24/outline'
import Button from '../../../UI/Button/Button'

const List: React.FC<{}> = () => {
  const translation = useSelector((s) => s[features.TRANSLATION])
  const [businesses, setBusinesses] = useState(null)
  const businessFeature = crud.use(Features.BUSINESS)

  useEffect(() => {
    const fetch = async () => {
      const businesses = await businessFeature.list({})
      if (Array.isArray(businesses)) {
        setBusinesses(businesses)
      }
    }

    if (!businesses) {
      fetch()
    }
  }, [businessFeature, businesses])

  return (
    <div>
      <Headline>{translation.dashboard.business.headline}</Headline>
      <Content>
        <div className="flex justify-end flex-col items-center gap-3 mt-3 sm:flex-row">
          <Upsert>
            <Button text={translation.dashboard.business.add} className="btn-sm">
              <PlusCircleIcon className="w-5 h-5" />
            </Button>
          </Upsert>
          <Button text={translation.dashboard.business.editCategories} className="btn-sm" href="/dashboard/business/categories">
            <TagIcon className="w-5 h-5" />
          </Button>
        </div>
        {!!businesses && (
          <ul className="divide-y divide-gray-200">
            {businesses.map((business: Business) => (
              <Item key={business.uuid} business={business} />
            ))}
          </ul>
        )}
        <Loading loading={businesses} />
      </Content>
    </div>
  )
}

export default List
