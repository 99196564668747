import { Route, Routes } from 'react-router-dom'
import Profile from './Profile/Profile'
import Stampcards from './Stampcards/Stampcards'
import StampcardView from './Stampcards/View/View'

const Home = () => {
  return (
    <Routes>
      <Route path={'/profile'} element={<Profile />} />
      <Route path={'/stampcards'} element={<Stampcards />} />
      <Route path={'/stampcards/:businessUUID'} element={<StampcardView />} />
      <Route path={'*'} element={<Stampcards />} />
    </Routes>
  )
}

export default Home
