import { useState } from 'react'
import { useSelector } from 'react-redux'
import { crud } from '@think-internet/zeus-frontend-package'
import Features from '../../../../../../redux/features'
import toast, { TYPE } from '../../../../../../toast'
import Admin from '../../../../../../types/Admin'
import Modal, { TYPE as ModalType } from '../../../../../UI/Modal/Modal'

type Props = {
  children: React.ReactNode | string
  removedCallback: (uuid: string) => void
  admin: Admin
}

const Remove: React.FC<Props> = ({ admin, children, removedCallback }) => {
  const [show, setShow] = useState(false)
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const adminFeature = crud.use(Features.ADMIN)

  const remove = async () => {
    const result = await adminFeature.remove({ uuid: admin.uuid })
    if (result) {
      setShow(false)
      removedCallback(admin.uuid)
    } else {
      toast(TYPE.ERROR, translation.dashboard.admins.list.remove.error)
    }
  }

  return (
    <>
      <div onClick={() => setShow(true)}>{children}</div>
      <Modal
        type={ModalType.WARNING}
        show={show}
        onClose={() => setShow(false)}
        onConfirm={remove}
        title={`"${admin.firstname} ${admin.lastname}"${translation.dashboard.admins.list.remove.title}`}
        text={translation.dashboard.admins.list.remove.message}
      />
    </>
  )
}

export default Remove
