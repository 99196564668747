import { ChevronRightIcon } from '@heroicons/react/24/solid'

interface IButton {
  text?: string
  onClick?: () => void
  children?: React.ReactNode
}

const ModalButton: React.FC<IButton> = ({ text, onClick, children }) => {
  return (
    <div className="btn bg-gray-100 border-none min-h-0 py-1 h-10 rounded-2xl font-bold flex items-center justify-between gap-2 cursor-pointer relative w-full flex-row" onClick={onClick}>
      {!!children ? children : null}
      <div className="uppercase block text-xs">{text}</div>
      <ChevronRightIcon className="w-5" />
    </div>
  )
}

export default ModalButton
