interface IProgress {
  progress: number
  radial?: boolean
}

const Progress: React.FC<IProgress> = ({ progress, radial = false }) => {
  if (!progress || progress === 0 || progress === 100) return null
  if (radial)
    return (
      <div className="radial-progress text-secondary" style={{ '--value': progress, '--size': '4rem', '--thickness': '5px' } as React.CSSProperties} role="progressbar">
        {progress} %
      </div>
    )
  return <progress className="progress progress-primary" value={progress} max="100"></progress>
}

export default Progress
