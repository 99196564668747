import { TrashIcon } from '@heroicons/react/24/outline'
import Admin from '../../../../../../types/Admin'
import Remove from './Remove'
import Button from '../../../../../UI/Button/Button'

type Props = {
  data: Admin
  removeCallback: (uuid: string) => void
}

const Item: React.FC<Props> = ({ data, removeCallback }) => {
  return (
    <li className="flex justify-between gap-x-6 py-2 items-center">
      <div>{`${data.firstname} ${data.lastname}`}</div>
      <Remove removedCallback={removeCallback} admin={data}>
        <Button className="btn-sm btn-error">
          <TrashIcon className="w-5 h-5" />
        </Button>
      </Remove>
    </li>
  )
}

export default Item
