import styled from 'styled-components'
import { keyframes } from 'styled-components'

const dropIn = keyframes`
    /* from {
      transform: scale(10) translate(50%, 50%) rotate(360deg);
      opacity: 0;
    }

    to {
      transform: scale(1) translate(0%, 0%);
      opacity: 1;
    } */
 
    0% { transform: scale(0); opacity: 0; }
    50% { transform: scale(1.2); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
`

const animatedgradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

export const Stamp = styled.div`
  width: calc(100% / 5 - 20px * 4 / 5);
  aspect-ratio: 1 / 1;
  border: 2.5px solid #656565;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  color: #000;
  position: relative;
`

export const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.color.background.primary};
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    top: calc(-1 * 2.5px);
    left: calc(-1 * 2.5px);
    height: calc(100% + 2.5px * 2);
    width: calc(100% + 2.5px * 2);
    background: rgb(69, 189, 255);
    background: linear-gradient(130deg, rgba(69, 189, 255, 1) 0%, rgba(251, 216, 125, 1) 100%);
    border-radius: 18px;
    z-index: -1;
    animation: ${animatedgradient} 5s ease infinite;
    background-size: 400% 400%;
  }
`

export const StampImage = styled.img`
  position: absolute;
  top: 1;
  left: 0px;
  width: calc(100% + 2px);
  max-width: fit-content;
  &.animate {
    animation: ${dropIn};
    animation-duration: 2s;
  }
`
