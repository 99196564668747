import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../redux/action/local'
import cookie from '../../helper/cookie'
import { useNavigate } from 'react-router-dom'
import Features from '../../../redux/features'
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid'

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const translation = useSelector((s) => s[Features.TRANSLATION])

  const logout = () => {
    cookie.remove('token')
    dispatch(setLocal(Features.ACCOUNT_LINK, null))
    dispatch(setLocal(Features.TOKEN, null))
    navigate('/')
  }

  return (
    <div className="btn bg-red-400 text-white border-none min-h-0 py-1 h-10 rounded-2xl font-bold flex items-center justify-between gap-2 cursor-pointer relative w-full flex-row" onClick={logout}>
      <ArrowRightOnRectangleIcon className="w-5" />
      <div className="uppercase block text-xs">{translation.navigation.logout}</div>
      {/* empty div is used for positioning */}
      <div />
    </div>
  )
}

export default Logout
