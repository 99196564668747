import User from '../../../../../types/User'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import features from '../../../../../redux/features'
import { crud } from '@think-internet/zeus-frontend-package'
import Features from '../../../../../redux/features'
import Loading from '../../../../UI/Loading/Loading'
import Item from './Item'

const Users: React.FC<{}> = () => {
  const translation = useSelector((s) => s[features.TRANSLATION])
  const [users, setUsers] = useState(null)
  const userFeature = crud.use(Features.USER)

  useEffect(() => {
    const fetch = async () => {
      const users = await userFeature.list({})
      if (Array.isArray(users)) {
        setUsers(users)
      }
    }

    if (!users) {
      fetch()
    }
  }, [userFeature, users])

  return (
    <div className="mt-3">
      <div className="font-bold border-b pb-1">{translation.dashboard.user.known.headline}</div>
      <Loading loading={users} />
      {Array.isArray(users) && users.length === 0 && <div className="py-2 text-center">{translation.dashboard.user.known.empty}</div>}
      {Array.isArray(users) && (
        <ul className="divide-y divide-gray-200">
          {users.map((user: User) => (
            <Item key={user.uuid} user={user} />
          ))}
        </ul>
      )}
    </div>
  )
}

export default Users
