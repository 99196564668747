import classNames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import Features from '../../../../../redux/features'
import Input from '../../../../UI/Input/Input'
import BusinessCategory from '../../../../../types/BusinessCategory'

type Props = {
  onChange(uuid: string): void
  label?: string
  initValue?: string
}

const CategoryAutocomplete = (props: Props) => {
  const [value, setValue] = useState(props.initValue || '')
  const [prevValue, setPrevValue] = useState(props.initValue || '')
  const { onChange, label } = props
  const ref = useRef<HTMLDivElement>(null)
  const businessCategoryFeature = crud.use(Features.BUSINESS_CATEGORY)
  const [suggestions, setSuggestions] = useState<Array<BusinessCategory>>([])
  const [categories, setCategories] = useState<Array<BusinessCategory>>(null)

  useEffect(() => {
    const getCategories = async () => {
      const categories = await businessCategoryFeature.list({})
      if (Array.isArray(categories)) {
        setCategories(categories)
      }
    }
    if (!categories) {
      getCategories()
    }
  }, [categories, businessCategoryFeature])

  useEffect(() => {
    const suggest = async () => {
      const filteredCategories = categories.filter((c) => c.name.toLowerCase().includes(value.toLowerCase()))
      if (Array.isArray(filteredCategories)) {
        setPrevValue(value)
        setSuggestions(filteredCategories)
      }
    }
    if (value.length >= 1 && value !== prevValue) {
      suggest()
    }
  }, [value, prevValue, categories])

  const select = (category: BusinessCategory) => async () => {
    setSuggestions([])
    setPrevValue(category.name)
    setValue(category.name)
    onChange(category.uuid)
  }

  const isVisible = () => suggestions.length > 0 && value.length >= 3

  return (
    <div
      className={classNames({
        'dropdown w-full': true,
        'dropdown-open': isVisible(),
      })}
      ref={ref}
    >
      <Input value={value} onChange={setValue} placeholder={label} />
      {isVisible() && (
        <div className="dropdown-content bg-base-200 top-11 max-h-24 overflow-auto flex-col rounded-md z-10">
          <ul className="menu menu-compact " style={{ width: ref.current?.clientWidth }}>
            {suggestions.map((c, index) => {
              return (
                <li key={index} tabIndex={index + 1} onClick={select(c)} className="w-full">
                  <button>{c.name}</button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default memo(CategoryAutocomplete)
