import './ripple.css'

const click = (e) => {
  let localX = e.clientX - e.target.getBoundingClientRect().left
  let localY = e.clientY - e.target.getBoundingClientRect().top

  if (!!e.touches) {
    localX = e.touches[0].clientX - e.target.getBoundingClientRect().left
    localY = e.touches[0].clientY - e.target.getBoundingClientRect().top
  }

  const ripple = document.createElement('span')
  ripple.classList.add('ripple')
  ripple.style.left = `${localX}px`
  ripple.style.top = `${localY}px`
  e.target.appendChild(ripple)
  setTimeout(() => {
    ripple.remove()
  }, 1000)
}

const eventType = 'ontouchstart' in window ? 'touchstart' : 'click'

document.addEventListener(eventType, (e) => {
  const target = e.target as HTMLElement
  if (target.classList.contains('btn')) {
    click(e)
  }
})
