import { useSelector } from 'react-redux'
import Admin from '../../../../../../types/Admin'
import Item from './Item'
import Features from '../../../../../../redux/features'

type Props = {
  admins: Array<Admin>
  removeCallback: (uuid: string) => void
}

const List: React.FC<Props> = ({ admins, removeCallback }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  if (!Array.isArray(admins)) return null
  return (
    <div>
      <ul className="divide-y divide-gray-200">
        {admins.map((admin: Admin) => (
          <Item key={admin.uuid} data={admin} removeCallback={removeCallback} />
        ))}
        {admins.length === 0 && <div className="py-2 text-center">{translation.dashboard.admins.list.empty}</div>}
      </ul>
    </div>
  )
}

export default List
