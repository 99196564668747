import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import logo from '../../../assets/image/logo.png'
import logoBlack from '../../../assets/image/logo_black.png'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { Level } from '../../../types/global'
import { defaultSiteURL } from '../../../utility'
import Link from '../Link/Link'

export enum Type {
  DEFAULT,
  FOOTER,
}

type Props = {
  className?: string
  type?: Type
}

const Logo: React.FC<Props> = ({ className, type = Type.DEFAULT }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[Features.ACCOUNT_LINK])

  const getLogo = () => {
    switch (type) {
      case Type.FOOTER:
        return <img className={`mx-auto h-8 w-auto ${className || ''}`} src={logoBlack} alt={translation.logoText} />
      default:
        return (
          <div className="flex flex-row gap-2 items-center">
            <img className={`mx-auto h-7 w-auto ${className || ''}`} src={logo} alt={translation.logoText} />
            <div className="text-white text-xs">{translation.logoText}</div>
          </div>
        )
    }
  }

  const getLink = () => {
    if (!accountLink || !accountLink.level) return defaultSiteURL
    if (accountLink.level === Level.ADMIN) return '/dashboard'
    return '/home'
  }

  if (!!getLink()) return <Link href={getLink()}>{getLogo()}</Link>
  return getLogo()
}

export default Logo
