import { ShieldExclamationIcon } from '@heroicons/react/24/solid'
import Features from '../../../redux/features'
import { useSelector } from 'react-redux'

const LockedPlaceholder = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  return (
    <div className="flex flex-col gap-3 items-center justify-center pt-10">
      <ShieldExclamationIcon className="h-28" />
      <div className="max-w-sm text-center">{translation.fingerprint.error}</div>
    </div>
  )
}

export default LockedPlaceholder
