import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import StampCard from '../../../types/StampCard'
import BusinessCategory from '../../../types/BusinessCategory'
import CategoryLabel from '../../UI/Category/Label/Label'

type Props = {
  value: string
  onChange: (value: string) => void
  businessList: Array<StampCard>
  className?: string
}

const Filter: React.FC<Props> = ({ value, onChange, businessList, className = '' }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  const getCategories = (): Array<BusinessCategory> => {
    const uniqueCategories = businessList.reduce((acc, business) => {
      if (!business.business.category) return acc
      if (acc.findIndex((c: BusinessCategory) => c.name === business.business.category.name) > -1) {
        return acc
      }
      return [...acc, business.business.category]
    }, [])

    return uniqueCategories
  }

  const toggle = (uuid: string) => () => onChange(uuid === value ? null : uuid)

  if (businessList.length === 0) return null
  return (
    <div className={`flex gap-2 items-center ${className}`}>
      <div className="uppercase font-bold text-sm">{translation.home.stampcards.filter.prefix}</div>
      <div className="overflow-x-auto flex gap-1 flex-row flex-nowrap no-scrollbar">
        {getCategories().map((c) => (
          <CategoryLabel active={c.name === value} onClick={toggle(c.name)} key={c.name} category={c} />
        ))}
      </div>
    </div>
  )
}

export default Filter
