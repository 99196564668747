import Headline from '../../Components/Headline/Headline'
import { useSelector } from 'react-redux'
import features from '../../../../redux/features'
import Content from '../../Components/Content/Content'
import Users from './Users/Users'
import AnonymousUsers from './AnonymousUsers/AnonymousUsers'
import Admins from './Admins/Admins'

const List: React.FC<{}> = () => {
  const translation = useSelector((s) => s[features.TRANSLATION])

  return (
    <div>
      <Headline>{translation.dashboard.user.headline}</Headline>
      <Content>
        <Admins />
        <Users />
        <div className="divider-horizontal"></div>
        <AnonymousUsers />
      </Content>
    </div>
  )
}

export default List
