import { useSelector } from 'react-redux'
import Features from '../../../../../redux/features'
import { useEffect, useState } from 'react'
import Button from '../../../../UI/Button/Button'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import { UserGroupIcon } from '@heroicons/react/24/solid'
import Modal from '../../../../UI/Modal/Modal'
import Loading from '../../../../UI/Loading/Loading'
import { getUUID } from '../../../../../utility'

type AnonymousMail = {
  uuid: string
  mail: string
}

const Mails: React.FC<{}> = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const anonymousMailFeature = crud.use(Features.ANONYMOUS_MAIL)
  const [show, setShow] = useState(false)
  const [anonymousMails, setAnonymousMails] = useState<Array<AnonymousMail>>(null)

  useEffect(() => {
    const list = async () => {
      const anonymousMails = await anonymousMailFeature.list({})
      if (Array.isArray(anonymousMails)) {
        setAnonymousMails(anonymousMails)
      } else {
        toast(TYPE.ERROR, translation.dashboard.user.anonymous.mail.error)
      }
    }
    if (!anonymousMails) {
      list()
    }
  }, [anonymousMails, translation, anonymousMailFeature])

  return (
    <div>
      <div className="mb-1 flex flex-col items-end w-full" onClick={() => setShow(true)}>
        <Button text={translation.dashboard.user.anonymous.mail.headline} className="h-[30px]">
          <UserGroupIcon className="w-5 h-5" />
        </Button>
      </div>
      <Modal confirmButtonText={translation.generic.save} show={show} onClose={() => setShow(false)} title={translation.dashboard.user.anonymous.mail.headline}>
        <div>
          <Loading loading={anonymousMails} />
          {anonymousMails && anonymousMails.map((anonymousMail) => <div key={getUUID()}>{anonymousMail.mail}</div>)}
        </div>
      </Modal>
    </div>
  )
}

export default Mails
