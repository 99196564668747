import { ReactNode, useEffect, useState } from 'react'
import Features from '../../../redux/features'
import { useDispatch, useSelector } from 'react-redux'
import cookie from '../../helper/cookie'
import cookieImage from '../../../assets/image/cookieConsent/cookie.png'
import Button from '../../UI/Button/Button'
import Settings from './Settings'
import { setLocal } from '../../../redux/action/local'
import props from '../../../redux/props'

const CookieConsent: React.FC<{ children: ReactNode }> = ({ children }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [isVisible, setIsVisible] = useState(false)
  const [settingsActive, setSettingsActive] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const consent = cookie.consent.get()
    if (consent) {
      dispatch(setLocal(props.COOKIE_CONSENT, consent))
    } else {
      setIsVisible(!consent)
    }
  }, [dispatch])

  const accept = () => {
    const consent = cookie.consent.init(translation, true)
    cookie.consent.set(consent)
    dispatch(setLocal(props.COOKIE_CONSENT, consent))
    setIsVisible(false)
  }

  const decline = () => {
    const consent = cookie.consent.init(translation, false)
    cookie.consent.set(consent)
    dispatch(setLocal(props.COOKIE_CONSENT, consent))
    setIsVisible(false)
  }

  if (isVisible) {
    return (
      <div className="py-10 px-10 h-full w-full absolute bg-white flex items-center justify-center">
        {!settingsActive && (
          <div className="flex flex-col gap-2 items-center">
            <img src={cookieImage} alt="Cookie" className="w-20" />
            <div className="text-secondary">{translation.cookieConsent.hi}</div>
            <div className="text-black text-2xl font-bold w-4/5 ml-auto mr-auto text-center">{translation.cookieConsent.question}</div>
            <Button className="btn-secondary text-white w-full mt-2" text={translation.cookieConsent.accept} onClick={accept} />
            <Button className="btn-sm text-black bg-gray-100 border-none w-full mt-1" text={translation.cookieConsent.decline} onClick={decline} />
            <div onClick={() => setSettingsActive(true)} className="text-black text-xs font-bold text-center underline mt-1">
              {translation.cookieConsent.settings.goTo}
            </div>
          </div>
        )}
        {settingsActive && <Settings back={() => setSettingsActive(false)} close={() => setIsVisible(false)} />}
      </div>
    )
  } else {
    return <>{children}</>
  }
}

export default CookieConsent
