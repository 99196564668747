import { getPrettyDateTime } from '../../../utility'
import plusOne from '../../../assets/image/stampcard/plus-one.png'
import reward from '../../../assets/image/stampcard/reward.png'
import Redeem from '../../../types/Redeem'
import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import { useNavigate } from 'react-router-dom'
import { HistoryRedeem, HistoryStamp } from './types'

type Props = {
  data: HistoryStamp | HistoryRedeem
  hideUserName: boolean
  hideBusinessName: boolean
}

const Item: React.FC<Props> = ({ data, hideUserName, hideBusinessName }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const navigate = useNavigate()

  const isStamp = (): boolean => {
    return (data as Redeem).stampUUIDList === undefined
  }

  const getText = (): string => {
    return isStamp() ? translation.stampCard.history.added.stamp : translation.stampCard.history.added.redeem
  }

  const getIcon = (): string => {
    return isStamp() ? plusOne : reward
  }

  const getUserName = (): string => {
    if (!!data.user) {
      return `${data.user.firstname} ${data.user.lastname}`
    }
    return translation.dashboard.user.anonymous.name
  }

  const goToUser = () => {
    if (!!data.user) {
      navigate(`/dashboard/user/view/${data.user.uuid}`)
    } else {
      navigate(`/dashboard/user/view-anonymous/${data.fingerprintUUID}`)
    }
  }

  const goToBusiness = () => navigate(`/dashboard/business/view/${data.businessUUID}`)

  return (
    <div className="bg-gray-200 rounded-2xl p-2 pr-8 flex gap-3 box-border max-w-sm w-full">
      <div className="w-1/5 flex-shrink-0 flex items-center justify-center">
        <img className="max-h-[40px] max-w-[40px]" src={getIcon()} alt={getText()} />
      </div>
      <div className="flex flex-col gap-0 justify-center w-4/5">
        <div className="truncate">{getText()}</div>
        {!hideUserName && (
          <p onClick={goToUser} className="truncate text-xs text-gray-500 cursor-pointer hover:underline">
            {getUserName()}
          </p>
        )}
        {!hideBusinessName && (
          <p onClick={goToBusiness} className="truncate text-xs text-gray-500 cursor-pointer hover:underline">
            {data.business?.name}
          </p>
        )}
        <p className="truncate text-xs text-gray-500">{getPrettyDateTime(new Date(data.createdOn))}</p>
      </div>
    </div>
  )
}

export default Item
