import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import Button from '../Button/Button'
import cookie from '../../helper/cookie'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

const LoginOrRegister = ({ contrastMode = false }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [showInfo, setShowInfo] = useState<boolean>(false)

  if (cookie.get('token')) return null
  return (
    <div className="w-full flex flex-col gap-3 mt-10 max-w-sm mx-auto">
      <div className="text-sm text-center flex items-center justify-center gap-1">
        {translation.loginOrRegister.title}
        <span className="font-bold">{translation.loginOrRegister.titleBold}</span>
        <span className="ml-1 cursor-pointer" onClick={() => setShowInfo(!showInfo)}>
          <InformationCircleIcon className="w-5" />
        </span>
      </div>
      {showInfo && <div className="text-sm font-bold">{translation.loginOrRegister.info.text}</div>}
      <Button className={`w-full ${contrastMode ? 'bg-white border-none' : ''}`} text={translation.loginOrRegister.register} href="/register" />
      <Button className={`w-full ${contrastMode ? 'bg-transparent border-white text-white' : 'bg-white outline-1'}`} text={translation.loginOrRegister.login} href="/login" />
    </div>
  )
}

export default LoginOrRegister
