import React from 'react'

interface IContainer {
  children: React.ReactNode
  className?: string
}

const Container: React.FC<IContainer> = ({ children, className }) => {
  return <div className={'container px-6 ' + (className || '')}>{children}</div>
}

export default Container
