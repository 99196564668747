import { InputHTMLAttributes, useRef, useState } from 'react'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: string
  onChange: (value: any) => void
  label?: string
  placeholder?: string
  className?: string
  required?: boolean
  Icon?: any
  value: any
}

const Input: React.FC<IProps> = ({ type = 'text', value, label, placeholder, onChange, required = false, className, Icon }) => {
  const [hasFocus, setHasFocus] = useState(false)
  // const [activeType, setActiveType] = useState(type)
  const ref = useRef<HTMLInputElement>(null)

  const prepValue = () => {
    if (!value) return ''
    if (type === 'date') {
      return new Date(value).toISOString().split('T')[0]
    } else {
      return value
    }
  }

  // useEffect(() => {
  //   if (type === 'date' && !value && !hasFocus) {
  //     setActiveType('text')
  //   }
  // }, [value, type, hasFocus])

  const activeFocus = () => {
    setHasFocus(true)
    // if (type === 'date' && activeType === 'text') {
    //   setActiveType('date')
    // }
  }

  const inactiveFocus = () => {
    setHasFocus(false)
    // if (type === 'date' && !value) {
    //   setActiveType('text')
    // }
  }

  return (
    <div className={`form-control border rounded-2xl overflow-hidden w-full bg-gray-100 flex-row items-center align-middle ${className} ${hasFocus ? 'border-secondary' : 'border-transparent'}`}>
      {Icon && (
        <div className="w-8 flex justify-end">
          <Icon className="h-5 text-black" />
        </div>
      )}
      <input
        ref={ref}
        required={required}
        type={type}
        value={prepValue()}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder || label}
        onFocus={activeFocus}
        onBlur={inactiveFocus}
        className="input w-full h-10 bg-transparent px-2 active:outline-none focus:outline-none"
      />
    </div>
  )
}

export default Input
