import { CakeIcon } from '@heroicons/react/24/solid'
import User from '../../../types/User'
import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import { useState } from 'react'
import Modal from '../../UI/Modal/Modal'
import Input from '../../UI/Input/Input'
import ModalButton from './ModalButton'

type Props = {
  user: User
  set: (value: any) => void
}

const Birthday: React.FC<Props> = ({ user, set }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [show, setShow] = useState(false)
  const [birthday, setBirthday] = useState(user.birthday || null)

  const save = () => {
    set(birthday)
    setShow(false)
  }

  return (
    <>
      <ModalButton onClick={() => setShow(true)} text={translation.generic.birthday}>
        <CakeIcon className="w-5" />
      </ModalButton>
      <Modal confirmButtonText={translation.generic.save} show={show} onClose={() => setShow(false)} onConfirm={save} title={translation.generic.birthday}>
        {user && (
          <div className="flex flex-col gap-3">
            <Input type="date" required label={translation.generic.birthday} onChange={setBirthday} value={birthday} />
          </div>
        )}
      </Modal>
    </>
  )
}

export default Birthday
