import Input from '../UI/Input/Input'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { auth } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../toast'
import { useNavigate } from 'react-router-dom'
import features from '../../redux/features'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { setLocal } from '../../redux/action/local'
import Form from '../UI/Form/Form'
import Container from '../UI/Container/Container'
import Button from '../UI/Button/Button'
import { Level } from '../../types/global'
import cookie from '../helper/cookie'
import { UserIcon, LockClosedIcon } from '@heroicons/react/24/solid'
import Link from '../UI/Link/Link'

const Login = () => {
  const translation = useSelector((s) => s[features.TRANSLATION])
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const submit = async () => {
    const token = (await auth.login(identifier, password)) as string
    if (token) {
      cookie.set('token', token)
      const accountLink: AccountLink | false = await auth.resolveToken(token)

      if (accountLink && accountLink.level) {
        dispatch(setLocal(features.ACCOUNT_LINK, accountLink))
        dispatch(setLocal(features.TOKEN, token))
        if (accountLink.level === Level.ADMIN) {
          navigate(`/dashboard`)
        } else if (accountLink.level === Level.DEFAULT) {
          navigate(`/home`)
        }
      } else {
        toast(TYPE.ERROR, translation.login.error)
      }
    } else {
      toast(TYPE.ERROR, translation.login.error)
    }
  }

  return (
    <Layout authLevelRequired={false}>
      <Container className="py-3">
        <div className="mt-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-7 text-2xl font-bold leading-9 font-headline">{translation.login.headline}</h2>
            <h2 className="text-secondary">{translation.login.subHeadline}</h2>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <Form className="flex flex-col gap-4" onSubmit={submit}>
              <Input label={translation.generic.mail} placeholder={translation.generic.mail} onChange={setIdentifier} value={identifier} required type="email" autoComplete="email" Icon={UserIcon} />

              <Input placeholder={translation.generic.password} onChange={setPassword} value={password} required={true} type="password" autoComplete="current-password" Icon={LockClosedIcon} />
              <Link href="/forgot-password" className="font-semibold underline block text-right text-sm">
                {translation.login.forgotPassword}
              </Link>

              <Button type="submit" className="w-full" text={translation.login.cta} />
            </Form>

            <p className="mt-5 text-center text-sm text-gray-500">{translation.login.noAccount} </p>
            <Link href="/register" className="text-center font-semibold leading-6 underline block">
              {translation.login.registerNow}
            </Link>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Login
