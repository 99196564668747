import { useSelector } from 'react-redux'
import Input from '../../../UI/Input/Input'
import Features from '../../../../redux/features'
import { useState } from 'react'
import { EnvelopeIcon } from '@heroicons/react/24/solid'
import Form from '../../../UI/Form/Form'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import props from '../../../../redux/props'
import Fingerprint from '../../../../types/Fingerprint'
import toast, { TYPE } from '../../../../toast'
import { useParams } from 'react-router-dom'
import Button from '../../../UI/Button/Button'
import Checkbox from '../../../UI/Checkbox/Checkbox'

const SendCard: React.FC<{}> = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const fingerprint: Fingerprint = useSelector((s) => s[props.FINGERPRINT])
  const [data, setData] = useState({ mail: '', mailRepeat: '' })
  const sendMail = functional.use(Routes.SEND_CARD_MAIL_FOR_ANONYMOUS_USER)
  const { businessUUID } = useParams()
  const [sent, setSent] = useState(false)

  const send = async () => {
    const result = await sendMail({ ...data, fingerprintUUID: fingerprint.uuid, businessUUID })
    if (result) {
      setSent(true)
    } else {
      toast(TYPE.ERROR, translation.stampCard.redeemLater.sendCard.error)
    }
  }

  const set = (key: string) => (value: string) => {
    setData({ ...data, [key]: value })
  }

  const mailMatches = () => {
    if (data.mail.length === 0 && data.mailRepeat.length === 0) return true
    return data.mail === data.mailRepeat
  }

  const submitIsEnabled = () => {
    return data.mail.length > 0 && data.mailRepeat.length > 0 && mailMatches()
  }

  return (
    <div className="my-5">
      {!sent && (
        <Form className="max-w-sm mx-auto flex flex-col gap-2" onSubmit={send}>
          <Input label={translation.generic.mail} onChange={set('mail')} value={data.mail} required type="email" autoComplete="email" Icon={EnvelopeIcon} />
          <Input label={translation.generic.mailRepeat} onChange={set('mailRepeat')} value={data.mailRepeat} required type="email" autoComplete="email" Icon={EnvelopeIcon} />
          {!mailMatches() && <div className="text-center font-bold text-sm">{translation.stampCard.redeemLater.sendCard.mailDoesNotMatch}</div>}
          {submitIsEnabled() && <Checkbox required label={translation.stampCard.redeemLater.sendCard.terms} />}
          {submitIsEnabled() && <Button type="submit" text={translation.stampCard.redeemLater.sendCard.cta} />}
        </Form>
      )}
      {sent && <div className="text-center font-bold">{translation.stampCard.redeemLater.sendCard.success}</div>}
    </div>
  )
}

export default SendCard
