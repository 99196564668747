import { crud } from '@think-internet/zeus-frontend-package'
import { useNavigate } from 'react-router-dom'
import Features from '../../../../../redux/features'
import toast, { TYPE } from '../../../../../toast'
import { translation } from '../../../../../translations/de'
import Modal, { TYPE as ModalType } from '../../../../UI/Modal/Modal'
import { useState } from 'react'

type Props = {
  uuid: string
  children: React.ReactNode | string
}

const Remove: React.FC<Props> = ({ uuid, children }) => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const businessFeature = crud.use(Features.BUSINESS)

  const remove = async () => {
    setShow(false)
    const result = await businessFeature.remove({ uuid })
    if (result) {
      toast(TYPE.SUCCESS, translation.dashboard.business.view.remove.success)
      navigate('/dashboard/business')
    } else {
      toast(TYPE.ERROR, translation.dashboard.business.view.remove.error)
    }
  }

  return (
    <>
      <div onClick={() => setShow(true)}>{children}</div>
      <Modal
        type={ModalType.WARNING}
        show={show}
        onClose={() => setShow(false)}
        onConfirm={remove}
        title={translation.dashboard.business.view.remove.modalTitle}
        text={translation.dashboard.business.view.remove.message}
      />
    </>
  )
}

export default Remove
