import { ReactNode } from 'react'
import { getUUID } from '../../../utility'

interface IFileSelect {
  label?: string
  onFile?: (file: any) => void
  acceptedMimeTypes?: string[]
  children?: ReactNode
}

const FileSelect: React.FC<IFileSelect> = ({ label, onFile, acceptedMimeTypes = null, children }) => {
  const uuid = getUUID()

  const changed = (e: any) => {
    let file = undefined
    if (e.target.files.length > 0) {
      file = e.target.files[0]
    }
    onFile(file)
  }

  return (
    <div className="form-control w-full">
      {!!label && !children && (
        <label className="label pt-0" htmlFor={uuid}>
          {!children && <span className="label-text">{label}</span>}
        </label>
      )}
      {children && <label htmlFor={uuid}>{children}</label>}
      <input
        type="file"
        id={uuid}
        accept={acceptedMimeTypes ? acceptedMimeTypes.join(',') : ''}
        onChange={changed}
        className={`file-input file-input-bordered file-input-sm file-input-ghost ${!!children ? ' hidden' : ''}`}
      />
    </div>
  )
}

export default FileSelect
