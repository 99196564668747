import styled from 'styled-components'

export const LabelStyled = styled.div<{ color: string; active: boolean }>`
  background-color: ${(props) => props.color};
  font-size: 0.6rem;
  ${({ active, theme }) =>
    active
      ? `
    color: white;
    font-family: bold;
  `
      : `color: ${theme.color.font.primary}`}
`
