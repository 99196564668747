import { CirclePicker } from 'react-color'

type Props = {
  label?: string
  currentColor: string
  onSelect: (color: string) => void
}

const ColorPicker: React.FC<Props> = ({ label, currentColor, onSelect }) => {
  return (
    <div>
      {label && <div className="mt-1">{label}</div>}
      <CirclePicker className="mt-1" color={currentColor} onChangeComplete={(color) => onSelect(color.hex)} circleSize={20} />
    </div>
  )
}

export default ColorPicker
