import { useSelector } from 'react-redux'
import Features from '../../../../redux/features'
import { getPrettyDateTime } from '../../../../utility'
import BountyLabel from '../../../UI/Bounty/Label'
import StampCard from '../../../../types/StampCard'
import getCurrentCard from '../../../UI/StampCard/Card/helper/getCurrentCard'

type Props = {
  stampCard?: StampCard
  hasAddedStamp?: boolean
  children?: React.ReactNode
}

const IsNotFull: React.FC<Props> = ({ stampCard, hasAddedStamp = false, children }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  const currentCard = getCurrentCard(stampCard)
  const stampsRemaining = stampCard.limits.stampsPerCard - currentCard.stampCount

  return (
    <div>
      {hasAddedStamp && <div className="font-bold text-center mb-1 mt-5 sm:mt-0">{translation.stampCard.hasAddedStamp}</div>}
      <div className="mt-3 flex items-center w-full justify-center gap-1 flex-wrap mb-5">
        <span className="font-bold">{translation.stampCard.left.prefix}</span>
        <BountyLabel business={stampCard.business} />
        {translation.stampCard.left.mid}
        <div className="font-bold">{stampsRemaining === 1 ? translation.stampCard.left.single : stampsRemaining}</div>
        {stampsRemaining === 1 ? translation.stampCard.left.suffixSingular : translation.stampCard.left.suffix}
      </div>
      {children}
      {typeof stampCard.isLockedUntil === 'string' && (
        <p className="mt-5 text-center text-s max-w-xs leading-5 text-gray-500 text-sm">
          {translation.stampCard.next.prefix}
          {getPrettyDateTime(new Date(stampCard.isLockedUntil))}
          {translation.stampCard.next.suffix}
        </p>
      )}
    </div>
  )
}

export default IsNotFull
