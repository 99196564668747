import React from 'react'
import { StyledHeadline, TextWrapper } from './Headline.Styled'

const Headline: React.FC<{ children: JSX.Element | string }> = ({ children }) => {
  return (
    <StyledHeadline>
      <TextWrapper>{children}</TextWrapper>
    </StyledHeadline>
  )
}

export default Headline
