import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import Features from '../../../../../redux/features'
import Admin from '../../../../../types/Admin'
import Create from './Create'
import List from './List/List'

const Admins: React.FC<{}> = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const adminFeature = crud.use(Features.ADMIN)
  const [admins, setAdmins] = useState<Array<Admin>>(null)

  useEffect(() => {
    const list = async () => {
      const admins = await adminFeature.list({})
      if (Array.isArray(admins)) {
        setAdmins(admins)
      } else {
        toast(TYPE.ERROR, translation.dashboard.admins.list.error)
      }
    }
    if (!admins) {
      list()
    }
  }, [admins, adminFeature, translation])

  const add = (admin: Admin) => {
    setAdmins([admin, ...admins])
  }

  const remove = (uuid: string) => {
    setAdmins([...admins].filter((a) => a.uuid !== uuid))
  }

  return (
    <div>
      <div className="w-full mt-5 border-b flex items-end justify-between flex-row">
        <div className="font-bold">{translation.dashboard.admins.list.headline}</div>
        <Create add={add} />
      </div>

      <List admins={admins} removeCallback={remove} />
    </div>
  )
}

export default Admins
