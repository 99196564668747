import BusinessCategory from '../../../../types/BusinessCategory'
import { LabelStyled } from './Label.styled'

type Props = {
  active?: boolean
  category: BusinessCategory
  onClick?: (name: string) => void
  className?: string
}

const CategoryLabel: React.FC<Props> = ({ category, onClick, active = false, className }) => {
  const click = () => {
    if (onClick) {
      onClick(category.name)
    }
  }

  if (!category) return null
  return (
    <LabelStyled active={active} onClick={click} color={category.color} className={`rounded-full px-3 py-1 uppercase w-fit ${className} ${!!onClick ? 'cursor-pointer' : ''}`}>
      {category.name}
    </LabelStyled>
  )
}

export default CategoryLabel
