import { LockClosedIcon } from '@heroicons/react/24/solid'
import User from '../../../types/User'
import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import { useState } from 'react'
import Modal from '../../UI/Modal/Modal'
import Input from '../../UI/Input/Input'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import toast, { TYPE } from '../../../toast'
import ModalButton from './ModalButton'

type Props = {
  user: User
}

const Password: React.FC<Props> = ({ user }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [show, setShow] = useState(false)
  const [data, setData] = useState({ newPassword: '', newPasswordConfirmation: '' })
  const [updated, setUpdated] = useState(false)
  const updatePassword = functional.use(Routes.UPDATE_PASSWORD)

  const save = async () => {
    if (data.newPassword !== data.newPasswordConfirmation) {
      toast(TYPE.ERROR, translation.home.profile.password.notEqual)
      return
    }
    const status = await updatePassword(data)
    if (status) {
      setUpdated(true)
    } else {
      toast(TYPE.ERROR, translation.home.profile.password.error)
    }
  }

  const set = (key: string) => (value: any) => {
    setData({ ...data, [key]: value })
  }

  return (
    <>
      <ModalButton onClick={() => setShow(true)} text={translation.home.profile.password.change}>
        <LockClosedIcon className="w-5" />
      </ModalButton>
      <Modal confirmButtonText={translation.generic.save} show={show} onClose={() => setShow(false)} onConfirm={!updated ? save : null} title={translation.home.profile.password.change}>
        {user && (
          <>
            {updated && <div className="text-center">{translation.home.profile.password.success}</div>}
            {!updated && (
              <div className="flex flex-col gap-3">
                <Input
                  className="mt-2"
                  placeholder={translation.home.profile.password.new}
                  onChange={set('newPassword')}
                  value={data.newPassword}
                  required
                  type="password"
                  autoComplete="new-password"
                  Icon={LockClosedIcon}
                />
                <Input
                  className="mt-2"
                  placeholder={translation.home.profile.password.confirm}
                  onChange={set('newPasswordConfirmation')}
                  value={data.newPasswordConfirmation}
                  required
                  type="password"
                  autoComplete="new-password"
                  Icon={LockClosedIcon}
                />
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  )
}

export default Password
