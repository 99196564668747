import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../toast'
import { useNavigate } from 'react-router-dom'
import features from '../../redux/features'
import Container from '../UI/Container/Container'
import { useParams } from 'react-router-dom'
import Loading from '../UI/Loading/Loading'
import Countdown from '../UI/Countdown/Countdown'
import Routes from '../../redux/routes'

const Confirm = () => {
  const translation = useSelector((s) => s[features.TRANSLATION])
  const confirm = functional.use(Routes.CONFIRM)
  const navigate = useNavigate()
  const { accountLinkUUID } = useParams()
  const [confirmed, setConfirmed] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    const submitConfirmation = async () => {
      setProcessing(true)
      const status = await confirm({ accountLinkUUID })
      if (status) {
        setConfirmed(true)
        setLoading(false)
      } else {
        toast(TYPE.ERROR, translation.confirm.error)
      }
    }

    if (accountLinkUUID) {
      if (!processing) {
        submitConfirmation()
      }
    } else {
      setLoading(false)
    }
  }, [accountLinkUUID, confirm, translation, processing])

  return (
    <Layout authLevelRequired={false}>
      <Container className="px-3 py-3">
        <div className="mt-10">
          <div className="mt-5 sm:mx-auto">
            <div className="sm:mx-auto">
              <h2 className="mt-7 text-2xl font-bold leading-9">{confirmed ? translation.confirm.confirmed : translation.confirm.headline}</h2>
            </div>
            <Loading loading={!loading} />
            {!loading && (
              <>
                {!confirmed && <p className="mt-5 text-center">{translation.confirm.waitingForEmailConfirmation}</p>}
                {confirmed && (
                  <p className="mt-5 text-center">
                    {translation.confirm.redirectCountdownPrefix}
                    <Countdown start={5} doneCallback={() => navigate('/login')} />
                    {translation.confirm.redirectCountdownSuffix}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Confirm
