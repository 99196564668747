import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import { useEffect, useState } from 'react'
import User from '../../../types/User'
import { Level } from '../../../types/global'
import { crud } from '@think-internet/zeus-frontend-package'
import Avatar from '../../UI/Avatar/Avatar'
import placeholder from '../../../assets/image/profile/placeholder.png'
import { Bars3Icon } from '@heroicons/react/24/solid'

const ToggleIcon = () => {
  const accountLink = useSelector((s) => s[Features.ACCOUNT_LINK])
  const [user, setUser] = useState<User>(null)
  const userFeature = crud.use(Features.USER)

  useEffect(() => {
    const getUser = async () => {
      const user = await userFeature.get({})
      if (user) {
        setUser(user)
      }
    }
    if (!user && accountLink && accountLink.level === Level.DEFAULT) {
      getUser()
    }
  }, [accountLink, user, userFeature])

  if (user) {
    return <Avatar className="w-[40px]" url={user?.imageURL || placeholder} alt={`${user.firstname} ${user.lastname}`} />
  }
  return <Bars3Icon className="w-[40px]" />
}

export default ToggleIcon
