import { functional } from '@think-internet/zeus-frontend-package'
import { useEffect, useState } from 'react'
import Routes from '../../../../../redux/routes'
import Loading from '../../../../UI/Loading/Loading'
import { useSelector } from 'react-redux'
import Features from '../../../../../redux/features'
import toast, { TYPE } from '../../../../../toast'
import { prettifyNumber } from '../../../../../utility'

type Props = {
  fingerprintUUID: string
}

const Statistics: React.FC<Props> = ({ fingerprintUUID }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [data, setData] = useState(null)
  const getStatistics = functional.use(Routes.GET_USER_STATISTICS)

  useEffect(() => {
    const fetch = async () => {
      const data = await getStatistics({ fingerprintUUID })

      if (data) {
        setData(data)
      } else {
        toast(TYPE.ERROR, translation.dashboard.user.view.statistics.error)
      }
    }
    if (!data) {
      fetch()
    }
  }, [data, fingerprintUUID, getStatistics, translation])

  const calculateMovement = (current: number, previous: number) => {
    const movement = current - previous
    const percentage = (movement / (previous === 0 ? 1 : previous)) * 100
    const direction = percentage > 0 ? '↗︎' : '↘︎'
    return `${direction} ${prettifyNumber(parseFloat(percentage.toFixed(0)))}% (${prettifyNumber(movement)})`
  }

  if (!data) return null
  return (
    <div className="stats stats-vertical lg:stats-horizontal shadow">
      <Loading loading={data} />
      {data && (
        <>
          <div className="stat">
            <div className="stat-title">{translation.dashboard.user.view.statistics.totalStampCount}</div>
            <div className="stat-value">{prettifyNumber(data.totalStampCount)}</div>
            <div className="stat-desc">{translation.dashboard.user.view.statistics.totalTimeframe}</div>
          </div>
          <div className="stat">
            <div className="stat-title">{translation.dashboard.user.view.statistics.stampCountThisMonth}</div>
            <div className="stat-value">{prettifyNumber(data.stampCountThisMonth)}</div>
            <div className="stat-desc">{calculateMovement(data.stampCountThisMonth, data.stampCountLastMonth)}</div>
          </div>

          <div className="stat">
            <div className="stat-title">{translation.dashboard.user.view.statistics.redeemedStampCardCount}</div>
            <div className="stat-value">{prettifyNumber(data.redeemedStampCardCount)}</div>
            <div className="stat-desc">{translation.dashboard.user.view.statistics.totalTimeframe}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default Statistics
