import { useSelector } from 'react-redux'
import Features from '../../../../redux/features'
import { Stamp, StyledCard, StampImage } from './Card.Styled'
import stampImage from '../../../../assets/image/stampcard/stamp-crown.png'
import virtualCardIcon from '../../../../assets/image/stampcard/virtual-card.png'
import { getBusinessName, getStampRelation } from '../../../../utility'
import StampCard from '../../../../types/StampCard'
import RedeemCard from './RedeemCard'
import getFullCardsAmount from './helper/getFullCardsAmount'
import getCurrentCard from './helper/getCurrentCard'

type Props = {
  stampCard: StampCard
  hasAddedStamp?: boolean
  redeemLater?: boolean
  showMultiCard?: boolean
  isSliderActive?: boolean
  isFull?: boolean
}

const CoreCard: React.FC<Props> = ({ stampCard, hasAddedStamp = false, isSliderActive = false, redeemLater = true, isFull = false }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  return (
    <StyledCard className={`flex-shrink-0 ${isSliderActive ? 'w-11/12' : 'w-full'}`}>
      <div className="gap-5 w-full bg-white rounded-2xl">
        <div className="flex gap-3 justify-between items-center p-3 pb-0">
          <div className="text-sm font-bold truncate">{getBusinessName(stampCard.business)}</div>
          <div className="text-xs font-bold">{getStampRelation(stampCard)}</div>
        </div>
        <div className="flex flex-wrap gap-5 w-full bg-white rounded-lg my-2 px-3 ">
          {[...Array(stampCard.limits.stampsPerCard)].map((e, i) => (
            <Stamp key={i}>
              {/* {i >= stampCard.stampCount ? i + 1 : ''} */}
              {i < stampCard.stampCount ? <StampImage className={hasAddedStamp && i === stampCard.stampCount - 1 ? 'animate' : ''} src={stampImage} alt="stamp" /> : null}
            </Stamp>
          ))}
        </div>
        <div className="flex justify-end gap-2 items-center border-t p-3 mt-3 border-[#f2f2f2]">
          <div className="text-xs text-right">{translation.stampCard.virtualCard}</div>
          <img className="h-3" src={virtualCardIcon} alt={translation.stampCard.virtualCard} />
        </div>
      </div>
      {isFull && <RedeemCard businessUUID={stampCard.business.uuid} redeemLater={redeemLater} />}
    </StyledCard>
  )
}

const Card: React.FC<Props> = ({ stampCard, hasAddedStamp = false, redeemLater, showMultiCard = true }) => {
  const fullCards = getFullCardsAmount(stampCard)
  const currentCard = getCurrentCard(stampCard)

  const isSliderActive = fullCards > 0

  const isCurrentCardFull = stampCard.limits.stampsPerCard === currentCard.stampCount

  if (!showMultiCard || !isSliderActive) return <CoreCard stampCard={stampCard} hasAddedStamp={hasAddedStamp} redeemLater={redeemLater} isFull={isCurrentCardFull} />

  return (
    <div className="flex flex-row gap-3 overflow-x-auto overflow-y-hidden p-1 no-scrollbar">
      {currentCard && <CoreCard stampCard={currentCard} hasAddedStamp={hasAddedStamp} redeemLater={redeemLater} isSliderActive={isSliderActive} isFull={isCurrentCardFull} />}
      {[...Array(fullCards)].map((e, i) => (
        <CoreCard key={i} stampCard={stampCard} redeemLater={false} isSliderActive={isSliderActive} isFull />
      ))}
    </div>
  )
}

export default Card
