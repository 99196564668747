import EmojiPicker from 'emoji-picker-react'
import Button from '../Button/Button'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Features from '../../../redux/features'

type Props = {
  onSelect: (emoji: string) => void
  currentEmoji?: string
}

const EmojiSelector: React.FC<Props> = ({ currentEmoji, onSelect }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [open, setOpen] = useState(false)

  const set = (emoji: string) => {
    onSelect(emoji)
    setOpen(false)
  }

  return (
    <div>
      <div className="flex items-center gap-2 mb-2">
        {currentEmoji && <div className="text-2xl">{currentEmoji}</div>}
        <Button className="h-[30px]" onClick={() => setOpen(!open)}>
          {open ? translation.UI.emojiSelector.close : translation.UI.emojiSelector.open}
        </Button>
      </div>
      {open && (
        <EmojiPicker
          previewConfig={{
            showPreview: false,
          }}
          skinTonesDisabled
          width={'100%'}
          height={'400px'}
          onEmojiClick={(emoji) => set(emoji.emoji)}
        />
      )}
    </div>
  )
}

export default EmojiSelector
