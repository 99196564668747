import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../redux/props'
import { setLocal } from '../../../redux/action/local'
import { functional } from '@think-internet/zeus-frontend-package'
import routes from '../../../redux/routes'
import cookie from '../../helper/cookie'
import LockedPlaceholder from './LockedPlaceholder'
import FingerprintType from '../../../types/Fingerprint'

const Fingerprint = ({ children }) => {
  const fingerprint: FingerprintType | false = useSelector((s) => s[props.FINGERPRINT])
  const fingerprintHandler = functional.use(routes.GET_FINGERPRINT)
  const cookieConsent = useSelector((s) => s[props.COOKIE_CONSENT])
  const dispatch = useDispatch()

  useEffect(() => {
    const generateFingerprint = async () => {
      try {
        const cookieFingerprintUUID = cookie.get('fingerprintUUID')

        const fingerprint = await fingerprintHandler({ cookieFingerprintUUID })

        if (fingerprint) {
          cookie.set('fingerprintUUID', fingerprint.uuid)
          dispatch(setLocal(props.FINGERPRINT, fingerprint))
        } else {
          cookie.remove('fingerprintUUID')
          dispatch(setLocal(props.FINGERPRINT, false))
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (!fingerprint && cookieConsent) {
      generateFingerprint()
    }
  }, [fingerprint, dispatch, fingerprintHandler, cookieConsent])

  if (fingerprint === false) return <LockedPlaceholder />
  if (fingerprint === null || fingerprint === undefined) return null
  return children
}

export default Fingerprint
