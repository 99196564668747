import Business from '../../../../types/Business'
import { prettyLocation } from '../../../../utility'
import { MapPinIcon } from '@heroicons/react/24/solid'

type Props = {
  business: Business
}

const Location: React.FC<Props> = ({ business }) => {
  if (!business.location) return null
  return (
    <div className="max-w-full text-xs flex gap-1 text-[#656565]">
      <MapPinIcon className="w-4 flex-shrink-0" />
      <div className="truncate">{prettyLocation(business.location)}</div>
    </div>
  )
}

export default Location
