import { useSelector } from 'react-redux'
import { useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import Business from '../../../../../types/Business'
import Features from '../../../../../redux/features'
import Modal from '../../../../UI/Modal/Modal'
import toast, { TYPE } from '../../../../../toast'
import Input from '../../../../UI/Input/Input'
import EmojiSelector from '../../../../UI/EmojiSelector/EmojiSelector'

type Props = {
  business: Business
  dataCallback: (data: Business) => void
}

const Bounty: React.FC<Props> = ({ business, dataCallback }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [data, setData] = useState<Business>(business)
  const businessFeature = crud.use(Features.BUSINESS)
  const [show, setShow] = useState(false)

  const save = async () => {
    const result = await businessFeature.update(data)
    if (!!result) {
      dataCallback(data)
      toast(TYPE.SUCCESS, translation.dashboard.business.view.bounty.success)
      setShow(false)
    } else {
      toast(TYPE.ERROR, translation.dashboard.business.view.bounty.error)
    }
  }

  const set = (key: string) => (value: any) => {
    setData({ ...data, [key]: value })
  }

  return (
    <>
      <div onClick={() => setShow(true)}>
        <div className="btn ml-1">{translation.dashboard.business.view.bounty.cta}</div>
      </div>
      <Modal confirmButtonText={translation.generic.save} show={show} onClose={() => setShow(false)} onConfirm={save} title={translation.dashboard.business.view.bounty.headlinePrefix + data.name}>
        {data && (
          <div className="flex flex-col gap-3">
            <Input required label={translation.dashboard.business.view.bounty.placeholder} onChange={set('bounty')} value={data.bounty} />
            <EmojiSelector currentEmoji={data.bountyEmoji} onSelect={set('bountyEmoji')} />
          </div>
        )}
      </Modal>
    </>
  )
}

export default Bounty
