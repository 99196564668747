import { useSelector } from 'react-redux'
import Content from '../../Components/Content/Content'
import Headline from '../../Components/Headline/Headline'
import Features from '../../../../redux/features'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import { useParams } from 'react-router-dom'
import toast, { TYPE } from '../../../../toast'
import Loading from '../../../UI/Loading/Loading'
import { prettyLocation } from '../../../../utility'
import Copy from '../../../UI/Copy/Copy'
import Settings from './Settings/Settings'
import OnlineSwitch from './OnlineSwitch/OnlineSwitch'
import Upsert from '../Upsert/Upsert'
import Statistics from './Statistics/Statistics'
import Business from '../../../../types/Business'
import Avatar from '../../../UI/Avatar/Avatar'
import Bounty from './Bounty/Bounty'
import History from '../../../UI/History/History'

const View = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [business, setBusiness] = useState<Business>(null)
  const businessFeature = crud.use(Features.BUSINESS)
  const { uuid } = useParams()

  useEffect(() => {
    const get = async () => {
      const business = await businessFeature.get({ uuid })
      if (business) {
        setBusiness(business)
      } else {
        toast(TYPE.ERROR, translation.generic.error.loading)
      }
    }
    if (!business) {
      get()
    }
  }, [business, businessFeature, uuid, translation])

  const getPublicBusinessLink = (uuid: string) => {
    const domain = window.location.origin
    return `${domain}/add-stamp/${uuid}`
  }

  return (
    <div>
      <Headline>{business?.name || ''}</Headline>
      <Content>
        {!!business && (
          <div className="flex flex-col gap-5 py-10">
            <div className="flex flex-col justify-between items-center mt-5 gap-2 lg:flex-row">
              <div className="flex items-center flex-col gap-x-4 md:flex-row">
                <Avatar url={business.logoURL} alt={business.name} />
                <div className="flex flex-col">
                  <div className="text-xl text-center md:text-left font-semibold leading-6 text-gray-900">{business.name}</div>
                  <p className="mt-1 text-center truncate text-s leading-5 text-gray-500 md:text-left">{prettyLocation(business.location)}</p>
                  {!!business.category && <p className="mt-1 text-center truncate text-s leading-5 text-gray-500 md:text-left">({business.category.name})</p>}
                </div>
              </div>
              <div className="flex flex-wrap gap-1 items-center justify-center mt-2 md:mt-0 md:flex-nowrap">
                {business.online === true && <Copy text={translation.dashboard.business.view.copy} content={getPublicBusinessLink(business.uuid)} enableTooltip />}
                <Upsert business={business} callback={setBusiness}>
                  <div className="btn ml-1">{translation.dashboard.business.view.detail.title}</div>
                </Upsert>
                <Bounty business={business} dataCallback={setBusiness} />
                <OnlineSwitch data={business} dataCallback={setBusiness} />
                <Settings uuid={business.uuid} />
              </div>
            </div>
            <Statistics businessUUID={business.uuid} />
            <History businessUUID={business.uuid} hideBusinessName />
          </div>
        )}
        {!business && <Loading loading={business} />}
      </Content>
    </div>
  )
}

export default View
