import { v4 as uuidv4 } from 'uuid'
import currencyFormatter from 'currency-formatter'
import prettyBytes from 'pretty-bytes'
import Location from './types/Location'
import Business from './types/Business'
import StampCard from './types/StampCard'

export const offlineMode = process.env.NODE_ENV !== 'production'
export const backendDomain = offlineMode ? process.env.REACT_APP_OFFLINE_BACKEND_DOMAIN : process.env.REACT_APP_ONLINE_BACKEND_DOMAIN

export const defaultSiteURL = 'https://www.customer-crown.com/'

export const getUUID = () => uuidv4()

export const googleAPIKey = ''

export const getQueryString = (string: String, requiredString: String) => {
  if (typeof string === 'string' && string.length > 0 && typeof requiredString === 'string') {
    string = string.substring(1)
    const split = string.split('=')
    return !string.includes('&') && split.length === 2 && split[0] === requiredString && split[1].length > 0 ? decodeURIComponent(split[1]) : false
  } else {
    return false
  }
}

const fill = (number: Number) => {
  const numberAsString = `${number}`
  return numberAsString.length === 1 ? `0${numberAsString}` : numberAsString
}

export const getPrettyTime = (date: Date) => {
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${hour}:${minute}`
}
export const getPrettyDateTime = (date: Date) => {
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${day}.${month}.${year} ${hour}:${minute}`
}
export const getPrettyDate = (date: Date) => {
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

export const addMissingZero = (input: String) => {
  if (`${input}`.length === 1) {
    return `0${input}`
  }
  return input
}

export const formatFileSize = (sizeInBytes: number, locale = 'de') => {
  return prettyBytes(sizeInBytes, { locale })
}

export const formatPrice = (priceInCents: number, locale = 'de-DE') => {
  return currencyFormatter.format(priceInCents / 100, { locale })
}

export const getLocalURLOfFile = (file: File) => URL.createObjectURL(file)

export const validateEmail = (email: String) => {
  return email.match(/^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}

export const prettyLocation = (location: Location) => {
  if (!location) return ''
  return location.name
}

export const getLocationLink = (location: Location) => {
  if (!location) return ''
  return `https://www.google.com/maps/place/${location.name}`
}

export const prettifyNumber = (number: number) => {
  return number.toLocaleString('de-DE')
}

export const cookieLiveTimeInMillis = 1000 * 60 * 60 * 24 * 365

export const getBusinessName = (business: Business) => {
  return business.name
}

export const getStampRelation = (stampCard: StampCard) => {
  return `${stampCard.stampCount > stampCard.limits.stampsPerCard ? stampCard.limits.stampsPerCard : stampCard.stampCount}/${stampCard.limits.stampsPerCard}`
}

export const getStampCardAmount = (stampCard: StampCard) => {
  // in case more stamps then on one card are collected
  if (stampCard.stampCount > stampCard.limits.stampsPerCard) {
    const stampCardsRaw = stampCard.stampCount / stampCard.limits.stampsPerCard
    if (stampCardsRaw % 1 > 0) {
      return Math.floor(stampCardsRaw) + 1
    } else {
      return Math.floor(stampCardsRaw)
    }
  }

  // fallback if less stamps than one full stamp card are collected
  return 1
}
