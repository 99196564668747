import { useSelector } from 'react-redux'
import Headline from '../Components/Headline'
import Content from '../Components/Content'
import Features from '../../../redux/features'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../toast'
import Loading from '../../UI/Loading/Loading'
import Routes from '../../../redux/routes'
import StampCardType from '../../../types/StampCard'
import { getUUID } from '../../../utility'
import Input from '../../UI/Input/Input'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import props from '../../../redux/props'
import Filter from './Filter'
import BusinessPreview from '../../UI/StampCard/BusinessPreview/BusinessPreview'
import Layout from '../../Layout/Layout'
import { Level } from '../../../types/global'
import Fingerprint from '../../../types/Fingerprint'

const Stampcards = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const fingerprint: Fingerprint = useSelector((s) => s[props.FINGERPRINT])
  const [businessList, setBusinessList] = useState<Array<StampCardType>>(null)
  const getVisitedBusinessList = functional.use(Routes.GET_VISITED_BUSINESS_LIST)
  const [search, setSearch] = useState('')
  const [filterByCategory, setFilterByCategory] = useState<string | null>(null)

  useEffect(() => {
    const list = async () => {
      const businessList = await getVisitedBusinessList({ fingerprintUUID: fingerprint.uuid })
      if (Array.isArray(businessList)) {
        setBusinessList(businessList)
      } else {
        toast(TYPE.ERROR, translation.generic.error.loading)
      }
    }
    if (!businessList && fingerprint) {
      list()
    }
  }, [translation, businessList, getVisitedBusinessList, fingerprint])

  const filter = (stampCard: StampCardType) => {
    if (search.length === 0 && filterByCategory === null) return true
    if (filterByCategory !== null && stampCard.business.category?.name !== filterByCategory) return false
    if (search.length === 0) return true
    return stampCard.business.name.toLowerCase().includes(search.toLowerCase())
  }

  return (
    <Layout authLevelRequired={[Level.DEFAULT]}>
      <Headline>{translation.home.stampcards.headline}</Headline>
      <Content className="w-full">
        {Array.isArray(businessList) && businessList.length === 0 && <div className="text-center text-sm mt-10">{translation.home.stampcards.empty}</div>}
        {Array.isArray(businessList) && businessList.length > 0 && (
          <>
            <Input className="mt-5" value={search} onChange={setSearch} placeholder={translation.generic.search} Icon={MagnifyingGlassIcon} />
            <Filter className="mt-4" value={filterByCategory} onChange={setFilterByCategory} businessList={businessList} />
            <div className="flex flex-col gap-3 mt-4 max-h-[70vh] overflow-y-auto">
              {businessList.filter(filter).map((stampCard) => (
                <BusinessPreview key={getUUID()} stampCard={stampCard} />
              ))}
            </div>
          </>
        )}
        <Loading loading={businessList} />
      </Content>
    </Layout>
  )
}

export default Stampcards
