import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import { Level } from '../../../types/global'
import { useNavigate } from 'react-router-dom'
import backIcon from '../../../assets/image/header/back.png'

type Props = {
  clickedCallback: () => void
}

const BackButton: React.FC<Props> = ({ clickedCallback }) => {
  const accountLink = useSelector((s) => s[Features.ACCOUNT_LINK])
  const navigate = useNavigate()

  const isVisible = () => {
    if (accountLink && accountLink.level === Level.DEFAULT) {
      const path = window.location.pathname
      const excludedPaths = ['/home/stampcards', '/home/stampcards/', '/home', '/home/', '/home/profile', '/home/profile/']
      if (!excludedPaths.includes(path)) {
        return true
      }
    }
    return false
  }

  const go = () => {
    clickedCallback()
    navigate('/home')
  }

  if (!isVisible()) return null
  return (
    <div className="cursor-pointer w-[35px] h-[35px] bg-white rounded-full font-sm flex items-center justify-center absolute left-3" onClick={go}>
      <img src={backIcon} alt={''} className="h-[20px]" />
    </div>
  )
}

export default BackButton
