import { functional } from '@think-internet/zeus-frontend-package'
import { useEffect, useState } from 'react'
import Routes from '../../../redux/routes'
import toast, { TYPE } from '../../../toast'
import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import Loading from '../Loading/Loading'
import Item from './Item'
import { HistoryRedeem, HistoryStamp } from './types'

type Props = {
  businessUUID?: string
  fingerprintUUID?: string
  hideUserName?: boolean
  hideBusinessName?: boolean
  className?: string
}

const History: React.FC<Props> = ({ businessUUID, fingerprintUUID, hideUserName = false, hideBusinessName = false, className = '' }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [history, setHistory] = useState<Array<HistoryStamp | HistoryRedeem> | false>(false)
  const getHistory = functional.use(Routes.GET_STAMP_CARD_HISTORY)

  useEffect(() => {
    const get = async () => {
      const history = await getHistory({ businessUUID, fingerprintUUID })
      if (history) {
        setHistory(history)
      } else {
        toast(TYPE.ERROR, translation.stampCard.history.error)
      }
    }
    if (!history) {
      get()
    }
  })

  return (
    <div className={`${className}`}>
      <Loading loading={history} />
      {Array.isArray(history) && history.length > 0 && (
        <>
          <div className="text-xl font-bold">{translation.stampCard.history.adminHeadline}</div>
          <div className="mt-2 max-w-sm max-h-[35vh] overflow-y-auto flex flex-col gap-2">
            {history.map((item) => (
              <Item key={item.uuid} data={item} hideUserName={hideUserName} hideBusinessName={hideBusinessName} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default History
