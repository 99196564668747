import Link from '../Link/Link'

interface IButton {
  text?: string
  className?: string
  type?: string
  onClick?: () => void
  href?: string
  children?: React.ReactNode
}

enum Type {
  DEFAULT = 'default',
  SUBMIT = 'submit',
}

const baseClasses = 'btn btn-primary min-h-0 py-1 h-10 rounded-2xl font-bold flex items-center justify-center gap-2 cursor-pointer relative'

const Button: React.FC<IButton> = ({ text, className, onClick, type = Type.DEFAULT, children, href }) => {
  if (type === Type.SUBMIT)
    return (
      <button className={baseClasses + ' ' + (className || '')} type="submit">
        {text}
      </button>
    )
  if (!!href) {
    return (
      <Link href={href} className={baseClasses + ' ' + (className || '')}>
        {!!children ? children : null}
        {text}
      </Link>
    )
  }
  return (
    <div className={baseClasses + ' ' + (className || '')} onClick={onClick}>
      {!!children ? children : null}
      {text}
    </div>
  )
}

export default Button
