import { useState } from 'react'
import Button from '../../Button/Button'
import Modal, { TYPE as ModalType } from '../../Modal/Modal'
import Features from '../../../../redux/features'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

type Props = {
  businessUUID: string
  redeemLater?: boolean
}

const RedeemCard: React.FC<Props> = ({ businessUUID, redeemLater }) => {
  const [show, setShow] = useState(false)
  const [showLater, setLaterShow] = useState(false)
  const navigate = useNavigate()
  const translation = useSelector((s) => s[Features.TRANSLATION])

  const confirm = () => navigate(`/visit/redeem/${businessUUID}`)

  const later = () => navigate(`/visit/redeem-later/${businessUUID}`)

  return (
    <>
      <div className="backdrop-brightness-50 rounded-2xl overflow-hidden flex flex-col gap-2 justify-center items-center px-5 absolute w-full h-full top-0 left-0">
        <Button className="btn-secondary text-white w-full" onClick={() => setShow(true)} text={translation.stampCard.redeem.cta} />
        {redeemLater && <Button className="btn-outline btn-secondary bg-white w-full" onClick={() => setLaterShow(true)} text={translation.stampCard.redeem.ctaLater} />}
      </div>
      <Modal type={ModalType.INFO} show={show} onClose={() => setShow(false)} onConfirm={confirm} title={translation.stampCard.redeem.modal.title} text={translation.stampCard.redeem.modal.message} />
      <Modal
        type={ModalType.INFO}
        show={showLater}
        onClose={() => setLaterShow(false)}
        onConfirm={later}
        title={translation.stampCard.redeem.laterModal.title}
        text={translation.stampCard.redeem.laterModal.message}
      />
    </>
  )
}

export default RedeemCard
