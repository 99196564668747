import { useSelector } from 'react-redux'
import Features from '../../../../../redux/features'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import Routes from '../../../../../redux/routes'
import FingerprintForAdmins from '../../../../../types/FingerprintForAdmins'

type Props = {
  data: FingerprintForAdmins
  dataCallback: (data: any) => void
}

const LockSwitch: React.FC<Props> = ({ data, dataCallback }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const toggleFingerprintLock = functional.use(Routes.TOGGLE_FINGERPRINT_LOCK)

  const toggle = async () => {
    const user = await toggleFingerprintLock({ fingerprintUUID: data.uuid })
    if (!!user) {
      dataCallback(user)
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.known.lock.error)
    }
  }

  return (
    <label className={`swap ml-1 btn ${data.isLocked ? 'btn-error' : 'btn-success'}`}>
      <input type="checkbox" onChange={toggle} checked={!data.isLocked} />
      <div className="swap-on">{translation.dashboard.user.anonymous.lock.off}</div>
      <div className="swap-off">{translation.dashboard.user.anonymous.lock.on}</div>
    </label>
  )
}

export default LockSwitch
