import StampCard from '../../../../../types/StampCard'

const getFullCardsAmount = (stampCard: StampCard): number => {
  const fullCardsAmount = Math.floor(stampCard.stampCount / stampCard.limits.stampsPerCard)
  const currentCardStamps = stampCard.stampCount % stampCard.limits.stampsPerCard

  if (fullCardsAmount === 0) return 0

  const fullCards = currentCardStamps > 0 ? fullCardsAmount : fullCardsAmount - 1

  return fullCards
}

export default getFullCardsAmount
