import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import features from '../../../../../redux/features'
import { functional } from '@think-internet/zeus-frontend-package'
import Loading from '../../../../UI/Loading/Loading'
import Item from './Item'
import Routes from '../../../../../redux/routes'
import FingerprintForAdmins from '../../../../../types/FingerprintForAdmins'
import Mails from './Mails'

const AnonymousUsers: React.FC<{}> = () => {
  const translation = useSelector((s) => s[features.TRANSLATION])
  const [anonymousUsers, setAnonymousUsers] = useState(null)
  const getAnonymousUsers = functional.use(Routes.GET_ANONYMOUS_USERS)

  useEffect(() => {
    const fetch = async () => {
      const anonymousUsers = await getAnonymousUsers()
      if (Array.isArray(anonymousUsers)) {
        setAnonymousUsers(anonymousUsers)
      }
    }

    if (!anonymousUsers) {
      fetch()
    }
  }, [getAnonymousUsers, anonymousUsers])

  return (
    <div className="mt-7 pb-5">
      <div className="w-full mt-5 border-b flex items-end justify-between flex-row">
        <div className="font-bold">{translation.dashboard.user.anonymous.headline}</div>
        <Mails />
      </div>
      <Loading loading={anonymousUsers} />
      {Array.isArray(anonymousUsers) && anonymousUsers.length === 0 && <div className="py-2 text-center">{translation.dashboard.user.anonymous.empty}</div>}
      {Array.isArray(anonymousUsers) && (
        <ul className="divide-y divide-gray-200">
          {anonymousUsers.map((fingerprint: FingerprintForAdmins) => (
            <Item key={fingerprint.uuid} fingerprint={fingerprint} />
          ))}
        </ul>
      )}
    </div>
  )
}

export default AnonymousUsers
