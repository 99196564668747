import { useSelector } from 'react-redux'
import partyImage from '../../../../assets/image/stampcard/party.png'
import Features from '../../../../redux/features'
import BountyLabel from '../../../UI/Bounty/Label'
import { getPrettyDate } from '../../../../utility'
import Routes from '../../../../redux/routes'
import props from '../../../../redux/props'
import { functional } from '@think-internet/zeus-frontend-package'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Layout from '../../../Layout/Layout'
import Loading from '../../../UI/Loading/Loading'
import Business from '../../../../types/Business'
import LoginOrRegister from '../../../UI/LoginOrRegister/LoginOrRegister'
import MinuteCountdown from '../../../UI/Countdown/Minute'
import Button from '../../../UI/Button/Button'
import Container from '../../../UI/Container/Container'
import Fingerprint from '../../../../types/Fingerprint'

const Now = () => {
  const redeemCard = functional.use(Routes.REDEEM)
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const fingerprint: Fingerprint = useSelector((s) => s[props.FINGERPRINT])
  const { businessUUID } = useParams()
  const [data, setData] = useState<Business | false>(null)
  const [redeemed, setRedeemed] = useState<boolean>(false)

  useEffect(() => {
    const redeem = async () => {
      const data = await redeemCard({ businessUUID, fingerprintUUID: fingerprint.uuid })
      if (data) {
        setData(data)
      } else {
        setData(false)
      }
    }
    if (fingerprint && businessUUID && data === null) {
      redeem()
    }
  }, [fingerprint, businessUUID, redeemCard, translation, data])

  const done = () => setRedeemed(true)

  return (
    <Layout authLevelRequired={false} removeMarginTop>
      {/* <AnimatedBackground className="pb-10 pt-24 h-screen"> */}
      <Container className="pb-10 pt-24 min-h-screen">
        <div className="text-center mt-5">
          <img className="h-24 inline" src={partyImage} alt={translation.stampCard.full.title} />
        </div>
        {data === null && (
          <div className="mt-10">
            <div className="text-center font-bold">{translation.stampCard.redeem.loading}</div>
            <Loading loading={data} />
          </div>
        )}
        {data === false && <div className="text-center font-bold mt-10">{translation.stampCard.redeem.error}</div>}
        {data && (
          <>
            {redeemed && (
              <>
                <div className="text-center mx-auto mt-5 text-sm">{translation.stampCard.redeem.success.redeemed}</div>
                <div className="text-center mx-auto mt-5 text-2xl font-bold">{translation.stampCard.redeem.success.thanks}</div>
              </>
            )}
            {!redeemed && (
              <>
                <div className="text-center text-2xl mt-5 ">{translation.stampCard.redeem.success.prefix}</div>
                <div className="text-2xl text-center">
                  <BountyLabel business={data} />
                </div>
                <div className="flex items-center uppercase text-sm gap-1 justify-center mt-8">
                  <div className="">{translation.stampCard.redeem.success.countdown.prefix}</div>
                  <MinuteCountdown start={15} doneCallback={done} />
                  <div className="">{translation.stampCard.redeem.success.countdown.suffix}</div>
                </div>
                <div className="font-bold text-sm text-center mt-4">{getPrettyDate(new Date())}</div>
                <Button onClick={done} className="max-w-sm mx-auto mt-5">
                  {translation.stampCard.redeem.success.cta}
                </Button>
                <div className="text-center max-w-xs mx-auto mt-5">{translation.stampCard.redeem.success.hint}</div>
              </>
            )}
          </>
        )}
        <LoginOrRegister />
      </Container>
      {/* </AnimatedBackground> */}
    </Layout>
  )
}

export default Now
