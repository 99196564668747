import classNames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'
import Input from '../Input/Input'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import Location from '../../../types/Location'
import { prettyLocation } from '../../../utility'

type Props = {
  onChange(val: Location): void
  label?: string
  initValue?: string
}

const CategoryAutocomplete = (props: Props) => {
  const [value, setValue] = useState(props.initValue || '')
  const [prevValue, setPrevValue] = useState(props.initValue || '')
  const { onChange, label } = props
  const ref = useRef<HTMLDivElement>(null)
  const getSuggestions = functional.use(Routes.GET_LOCATION_SUGGESTION)
  const getCoordinates = functional.use(Routes.GET_GOOGLE_PLACE_COORDINATES)
  const [suggestions, setSuggestions] = useState<Array<Location>>([])

  useEffect(() => {
    const suggest = async () => {
      const suggestions = await getSuggestions({ value })
      if (Array.isArray(suggestions)) {
        setPrevValue(value)
        setSuggestions(suggestions)
      }
    }
    if (value.length >= 3 && value !== prevValue) {
      suggest()
    }
  }, [value, getSuggestions, prevValue])

  const select = (location: Location) => async () => {
    setSuggestions([])
    setPrevValue(prettyLocation(location))
    setValue(prettyLocation(location))
    const coordinates = await getCoordinates({ place_id: location.place_id })

    if (coordinates) {
      onChange({ ...location, coordinates })
    }
  }

  const isVisible = () => suggestions.length > 0 && value.length >= 3

  return (
    <div
      className={classNames({
        'dropdown w-full': true,
        'dropdown-open': isVisible(),
      })}
      ref={ref}
    >
      <Input value={value} onChange={setValue} placeholder={label} />
      {isVisible() && (
        <div className="dropdown-content bg-base-200 top-11 max-h-24 overflow-auto flex-col rounded-md z-10">
          <ul className="menu menu-compact " style={{ width: ref.current?.clientWidth }}>
            {suggestions.map((s, index) => {
              return (
                <li key={index} tabIndex={index + 1} onClick={select(s)} className="w-full">
                  <button>{prettyLocation(s)}</button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default memo(CategoryAutocomplete)
