import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import React, { useEffect, useState } from 'react'
import toast, { TYPE } from '../../../toast'
import Features from '../../../redux/features'
import StampCardType from '../../../types/StampCard'
import Loading from '../../UI/Loading/Loading'
import Card from './Card/Card'
import { useLocation } from 'react-router-dom'
import Fingerprint from '../../../types/Fingerprint'

export type Wrapper = React.FC<{ stampCard: StampCardType; hasAddedStamp: boolean; children: React.ReactNode }>

type Props = {
  businessUUID: string
  redeemLater?: boolean
  Wrapper?: Wrapper
}

const StampCard: React.FC<Props> = ({ businessUUID, redeemLater, Wrapper }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const fingerprint: Fingerprint = useSelector((s) => s[props.FINGERPRINT])
  const getStampCard = functional.use(Routes.GET_STAMP_CARD)
  const [stampCard, setStampCard] = useState<StampCardType>(null)
  const location = useLocation()

  const hasNewStamp = () => {
    const addedStamp = new URLSearchParams(location.search).get('addedStamp')
    if (addedStamp === undefined || addedStamp === null) return false
    return addedStamp === 'true'
  }

  useEffect(() => {
    const fetchStampCard = async () => {
      const stampCard = await getStampCard({ businessUUID, fingerprintUUID: fingerprint.uuid })
      if (stampCard) {
        setStampCard(stampCard)
      } else {
        toast(TYPE.ERROR, translation.stampCard.error)
      }
    }
    if (fingerprint && !stampCard) {
      fetchStampCard()
    }
  }, [fingerprint, stampCard, getStampCard, businessUUID, translation])

  return (
    <div className={'flex justify-center relative'}>
      <div className="w-full box-border">
        <Loading loading={stampCard} />
        {stampCard && (
          <div>
            {Wrapper && (
              <Wrapper stampCard={stampCard} hasAddedStamp={hasNewStamp()}>
                <Card stampCard={stampCard} hasAddedStamp={hasNewStamp()} redeemLater={redeemLater} />
              </Wrapper>
            )}
            {!Wrapper && <Card stampCard={stampCard} hasAddedStamp={hasNewStamp()} redeemLater={redeemLater} />}
          </div>
        )}
      </div>
    </div>
  )
}

export default StampCard
