//
// ONLY EDIT THIS FILE IN /frontend/src/
//

import { Level } from '../types/global'

export const translation = {
  logoText: 'Customer Crown',

  navigation: {
    headline: 'Menü',
    [Level.ADMIN]: [
      {
        label: 'Unternehmen',
        path: '/dashboard/business',
      },
      {
        label: 'Mitglieder',
        path: '/dashboard/user',
      },
    ],
    [Level.DEFAULT]: [
      {
        label: 'Mein Profil',
        path: '/home/profile',
        icon: 'user',
      },
      {
        label: 'Meine Karten',
        path: '/home/stampcards',
        icon: 'stampcard',
      },
      // {
      //   label: 'Einstellungen',
      //   path: '/home/settings',
      //   icon: 'settings',
      // },
    ],
    // public: [
    //   {
    //     label: 'Login',
    //     path: '/login',
    //   },
    //   {
    //     label: 'Registrieren',
    //     path: '/register',
    //   },
    // ],
    logout: 'Logout',
  },

  home: {
    profile: {
      settings: 'Einstellungen:',
      profile: 'Profil',
      security: 'Sicherheit',
      other: 'Sonstiges',
      selectImage: 'Bild auswählen',
      mail: {
        change: 'E-Mail Adresse ändern',
        confirm: 'Wir haben dir eine Bestätigungsmail an deine neue E-Mail Adresse geschickt.',
        error: 'E-Mail Adresse wird bereits verwendet.',
      },
      password: {
        change: 'Passwort ändern',
        new: 'Neues Passwort',
        confirm: 'Neues Passwort bestätigen',
        success: 'Passwort erfolgreich geändert.',
        notEqual: 'Passwörter stimmen nicht überein.',
        error: 'Passwort konnte nicht geändert werden.',
      },
      update: {
        success: 'Profil erfolgreich aktualisiert.',
        error: 'Profil konnte nicht aktualisiert werden.',
      },
    },
    stampcards: {
      headline: 'Meine Karten',
      empty: 'Du hast noch keine Karten.',
      filter: {
        prefix: 'Filter: ',
      },
    },
  },

  dashboard: {
    businessCategory: {
      headline: 'Kategorien',
      add: 'Neue Kategorie erstellen',
      color: 'Farbe: ',
      remove: {
        title: 'Löschen',
        modalTitleSuffix: ' löschen?',
        message: 'Möchtest du diese Kategorie wirklich löschen? Diese Kategorie wird dann auch bei allen Unternehmen gelöscht, die diese Kategorie haben.',
        error: 'Kategorie konnte nicht gelöscht werden.',
      },
      upsert: {
        headline: 'Neue Kategorie erstellen',
        name: 'Name',
        colorPickerLabel: 'Farbe wählen:',
        cta: 'Erstellen',
        success: 'Kategorie erfolgreich gespeichert.',
        error: 'Kategorie konnte nicht gespeichert werden.',
      },
    },
    business: {
      headline: 'Unternehmen',
      add: 'Neues Unternehmen erstellen',
      editCategories: 'Kategorien bearbeiten',
      upsert: {
        headline: 'Neues Unternehmen erstellen',
        name: 'Name',
        selectLogo: 'Logo auswählen',
        selectCategory: 'Kategorie auswählen',
        metaPixelID: 'Meta-Pixel-ID',
        cta: 'Erstellen',
        success: 'Unternehmen erfolgreich gespeichert.',
        error: 'Unternehmen konnte nicht gespeichert werden.',
      },
      view: {
        copy: 'NFC-Link kopieren',
        remove: {
          title: 'Löschen',
          modalTitle: 'Unternehmen löschen?',
          message: 'Möchtest du das Unternehmen wirklich löschen?',
          success: 'Unternehmen erfolgreich gelöscht.',
          error: 'Unternehmen konnte nicht gelöscht werden.',
        },
        detail: {
          title: 'Details',
        },
        onlineSwitch: {
          online: 'Online',
          offline: 'Offline',
          error: 'Unternehmen konnte nicht aktualisiert werden.',
        },
        statistics: {
          totalStampCount: 'Gesamtanzahl Punkte',
          stampCountThisMonth: 'Punkte diesen Monat',
          redeemedStampCardCount: 'Eingelöste Karten',
          totalTimeframe: 'Gesamter Zeitraum',
          totalUserCount: 'Gesamtanzahl Mitglieder',
          userCountThisMonth: 'Mitglieder diesen Monat',

          error: 'Statistiken konnten nicht geladen werden.',
        },
        bounty: {
          cta: 'Belohnungen',
          headlinePrefix: 'Belohnungen für die Kunden von ',
          placeholder: 'Name der Belohnung',
          success: 'Belohnungen erfolgreich gespeichert.',
          error: 'Belohnungen konnten nicht gespeichert werden.',
        },
      },
    },
    user: {
      headline: 'Mitglieder',
      known: {
        headline: 'Bekannte Mitglieder',
        empty: 'Keine bekannten Mitglieder.',
        lock: {
          error: 'Mitglied konnte aktualisiert werden.',
          on: 'Gesperrt',
          off: 'Aktiv',
        },
      },
      anonymous: {
        headline: 'Anonyme Mitglieder',
        name: 'Anonym',
        possibleMail: 'Mögliche E-Mail Adresse: ',
        empty: 'Keine anonymen Mitglieder.',
        lock: {
          error: 'Mitglied konnte aktualisiert werden.',
          on: 'Gesperrt',
          off: 'Aktiv',
        },
        mail: {
          headline: 'Anonyme E-Mail Adressen',
          empty: 'Keine E-Mail Adressen vorhanden.',
          error: 'Anonyme E-Mail Adressen konnten nicht geladen werden.',
        },
      },
      editCategories: 'Kategorien bearbeiten',
      upsert: {
        headline: 'Neues Unternehmen erstellen',
        name: 'Name',
        selectLogo: 'Logo auswählen',
        selectCategory: 'Kategorie auswählen',
        cta: 'Erstellen',
        success: 'Unternehmen erfolgreich gespeichert.',
        error: 'Unternehmen konnte nicht gespeichert werden.',
      },
      view: {
        fingerprint: {
          headline: 'Fingerprint',
          id: 'ID: ',
          empty: 'Keine Fingerprint vorhanden.',
        },
        statistics: {
          totalStampCount: 'Gesamtanzahl Punkte',
          stampCountThisMonth: 'Punkte diesen Monat',
          redeemedStampCardCount: 'Eingelöste Karten',
          totalTimeframe: 'Gesamter Zeitraum',
          error: 'Statistiken konnten nicht geladen werden.',
        },
        remove: {
          title: 'Löschen',
          modalTitle: 'Mitglied löschen?',
          message: 'Möchtest du das Mitglied wirklich löschen? Alle Punkte und eingelöste Karten werden ebenfalls gelöscht.',
          success: 'Mitglied erfolgreich gelöscht.',
          error: 'Mitglied konnte nicht gelöscht werden.',
        },
      },
    },
    admins: {
      create: {
        headline: 'Admin erstellen',
        success: 'Admin erfolgreich erstellt.',
        error: 'Admin konnte nicht erstellt werden.',
      },
      list: {
        headline: 'Admins',
        error: 'Admins konnten nicht geladen werden.',
        empty: 'Keine Admins vorhanden.',
        remove: {
          error: 'Admin konnte nicht gelöscht werden.',
          title: ' löschen?',
          message: 'Möchtest du diesen Admin wirklich löschen?',
        },
      },
    },
  },

  visit: {
    offline: `Dieses Unternehmen ist aktuell offline auf Customer Crown.`,
    error: 'Unternehmen konnte nicht geladen werden.',
  },

  stampCard: {
    hasAddedStamp: 'Yeah! Du hast einen neuen Punkt!',
    left: {
      prefix: 'Gratis',
      mid: 'nach',
      single: 'einem',
      suffix: 'weiteren Punkten. ',
      suffixSingular: ' weiteren Punkt. ',
    },
    next: {
      prefix: 'Ab ',
      suffix: ' kannst du hier deinen nächsten Punkt sammeln.',
    },
    full: { title: 'Herzlichen Glückwunsch!', points: { prefix: 'Als Belohnung bekommst du ', suffix: "auf's Haus!" }, bounty: { first: 'Du hast ', second: ' 10 Punkte bei ', third: ' gesammelt!' } },
    redeem: {
      cta: 'Jetzt einlösen',
      ctaLater: 'beim nächsten Mal einlösen',
      loading: 'Karte wird eingelöst...',
      success: {
        countdown: {
          prefix: 'noch',
          suffix: 'aktiv',
        },
        cta: 'Belohnung erhalten',
        prefix: 'Du erhältst:',
        hint: 'Zeige dein Handy an der Kasse vor, um deine Prämie zu erhalten.',
        redeemed: 'Deine Karte wurde erfolgreich eingelöst.',
        thanks: 'Danke für deinen Besuch!',
      },
      modal: {
        title: 'Karte einlösen?',
        titleLoading: 'Karte wird eingelöst...',
        message: 'Möchtest du deine Karte wirklich einlösen?',
      },
      laterModal: {
        title: 'Karte später einlösen?',
        message: 'Möchtest du deine Karte wirklich später einlösen?',
      },
      error: 'Deine Karte konnte nicht eingelöst werden.',
    },
    redeemLater: {
      title: {
        prefix: 'Du hast 10 Punkte bei ',
        suffix: ' gesammelt',
      },
      bounty: {
        prefix: 'Löse deine Belohnung beim nächsten mal ein und erhalte: ',
      },
      hint: {
        divide: 'ODER',
        textKnownUser: 'In deinem Account unter "Meine Karten" findest du alle deine bisher gesammelten Karten.',
        textUnknownUser: 'Trage hier deine E-Mail Adresse ein, um dir deine Karte zuschicken zu lassen oder erstelle dir deinen Account, um sie für später zu speichern.',
      },
      sendCard: {
        cta: 'Karte zuschicken',
        mailDoesNotMatch: 'E-Mail Adressen stimmen nicht überein.',
        success: 'Wir haben dir deine Karte zugeschickt.',
        error: 'Karte konnte nicht zugeschickt werden.',
        terms: 'Indem du deine E-Mail Adresse angibst, stimmst du unseren Nutzungsbedingungen und unserer Datenschutzerklärung zu.',
      },
    },
    history: {
      adminHeadline: 'Historie',
      headline: 'Meine Historie',
      subheadline: 'Diese Punkte hast du gesammelt',
      error: 'Historie konnten nicht geladen werden.',
      added: {
        stamp: 'Punkt hinzugefügt.',
        redeem: 'Gratis Prämie erhalten.',
      },
    },
    virtualCard: 'virtuelle Karte',
    error: 'Deine Karte konnte nicht geladen werden.',
    businessPreview: {
      lastVisit: 'Letzter Besuch: ',
    },
  },

  fingerprint: {
    error: 'Zugriff gesperrt.',
  },

  copy: {
    cta: 'Kopieren',
    success: 'Kopiert',
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  generic: {
    salutation: 'Anrede',
    firstname: 'Vorname',
    lastname: 'Nachname',
    street: 'Straße',
    postcode: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefon',
    fax: 'Fax',
    mail: 'E-Mail',
    mailRepeat: 'E-Mail wiederholen',
    password: 'Passwort',
    save: 'Speichern',
    copy: 'Kopieren',
    copied: 'Kopiert',
    confirm: 'Bestätigen',
    cancel: 'Schließen',
    location: 'Standort',
    lastUsed: 'Zuletzt gesehen: ',
    birthday: 'Geburtstag',
    search: 'Suchen...',
    error: {
      loading: 'Daten konnten nicht geladen werden.',
    },
  },

  login: {
    headline: 'Willkommen zurück!',
    subHeadline: 'Anmelden:',
    cta: 'Login',
    noAccount: 'Du hast noch keinen Account?',
    registerNow: 'Account erstellen',
    forgotPassword: 'Passwort vergessen?',
    error: 'E-Mail Adresse oder Passwort falsch.',
  },

  register: {
    headline: 'Account erstellen',
    subHeadline: 'Registriere dich hier:',
    cta: 'Account erstellen',
    alreadyHasAnAccount: 'Du hast schon einen Account?',
    loginNow: 'Jetzt anmelden',
    passwordConfirmation: 'Passwort bestätigen',
    error: 'Deine E-Mail Adresse wird bereits verwendet.',
    agreeToTerms: 'Indem du dich anmeldest, stimmst du unseren Nutzungsbedingungen und unserer Datenschutzerklärung zu.',
    passwordsDontMatch: 'Deine Passwörter stimmen nicht überein.',
  },

  loginOrRegister: {
    title: 'Sammel deine Punkte',
    titleBold: 'geräteübergreifend',
    register: 'Account erstellen',
    login: 'Anmelden',
    info: {
      text: 'Um deine Punkte browser- und geräteübergreifend zu sammeln, musst du dich registrieren oder anmelden, damit wir dich auch auf einem anderen oder neuen Gerät erkennen können.',
    },
  },

  confirm: {
    headline: 'Account bestätigen...',
    confirmed: 'Dein Account wurde erfolgreich bestätigt!',
    redirectCountdownPrefix: 'Du wirst in ',
    redirectCountdownSuffix: ' Sekunden weitergeleitet...',
    waitingForEmailConfirmation: 'Bitte bestätige deinen Account über den Link in der E-Mail.',
    cta: 'Bestätigen',
    error: 'Dein Account konnte nicht bestätigt werden.',
  },

  confirmNewMail: {
    headline: 'Neue E-Mail Adresse bestätigen...',
    confirmed: 'Deine neue E-Mail Adresse wurde erfolgreich bestätigt!',
    redirectCountdownPrefix: 'Du wirst in ',
    redirectCountdownSuffix: ' Sekunden weitergeleitet...',
    error: 'Deine E-Mail Adresse konnte nicht bestätigt werden.',
  },

  forgotPassword: {
    headline: 'Passwort vergessen?',
    cta: 'Passwort zurücksetzen',
    backToLogin: 'Zurück zum Login',
    error: 'E-Mail Adresse nicht gefunden.',
    success: 'Eine E-Mail zum Zurücksetzen deines Passworts wurde versendet.',
  },

  setNewPassword: {
    headline: 'Neues Passwort setzen',
    cta: 'Passwort setzen',
    passwordConfirmation: 'Passwort bestätigen',
    error: 'Passwort konnte nicht gesetzt werden.',
    success: 'Passwort erfolgreich gesetzt.',
    passwordsDontMatch: 'Deine Passwörter stimmen nicht überein.',
  },

  footer: {
    categories: [
      // {
      //   label: 'Services',
      //   links: [
      //     {
      //       label: 'Für Unternehmen',
      //       path: '/for-business',
      //     },
      //     {
      //       label: 'Für Mitglieder',
      //       path: '/for-members',
      //     },
      //   ],
      // },
      // {
      //   label: 'Unternehmen',
      //   links: [
      //     {
      //       label: 'Über uns',
      //       path: '/about-us',
      //     },
      //     {
      //       label: 'Contact',
      //       path: '/contact',
      //     },
      //     {
      //       label: 'Jobs',
      //       path: '/jobs',
      //     },
      //     {
      //       label: 'Pressekit',
      //       path: '/press-kit',
      //     },
      //   ],
      // },
      {
        label: 'Rechtliches',
        links: [
          {
            label: 'Impressum',
            path: 'https://www.customer-crown.com/impressum/',
          },
          {
            label: 'Datenschutz',
            path: 'https://www.customer-crown.com/datenschutz/',
          },
        ],
      },
    ],
  },

  cookieConsent: {
    hi: 'Hi, alles klar?',
    question: 'Darf es ein Cookie zu deiner Bestellung sein?',
    accept: 'Cookies akzeptieren',
    decline: 'Ablehnen',
    settings: {
      goTo: 'Zu den Cookie-Einstellungen',
      back: '< Zurück',
      save: 'Speichern',
    },
    cookies: [
      {
        type: 'Marketing',
        description: 'Diese Technologien werden verwendet, um Inhalte zu personalisieren, die für dich relevant sind.',
        items: [
          {
            name: 'fingerprintUUID',
            description: 'Speichert den Fingerprint des Geräts.',
          },
        ],
      },
      {
        type: 'Essentiell',
        disabled: true,
        description: 'Diese Technologien sind erforderlich, um die Kernfunktionalität der Website zu aktivieren.',
        items: [
          {
            name: 'cookie_consent',
            description: 'Speichert die Auswahl des Cookie-Consents.',
          },
          {
            name: 'token',
            description: 'Speichert das Login-Token.',
          },
        ],
      },
    ],
  },

  UI: {
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
    emojiSelector: {
      open: 'Emoji auswählen',
      close: 'Schließen',
    },
    bounty: {
      label: {
        fallback: 'Belohnung',
      },
    },
  },
}

export const langKey = 'DE'
