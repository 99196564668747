import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Routes from '../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import Layout from '../Layout/Layout'
import Container from '../UI/Container/Container'
import Loading from '../UI/Loading/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import history from 'history/browser'
import Fingerprint from '../../types/Fingerprint'

const AddStamp = () => {
  const { businessUUID } = useParams()
  const fingerprint: Fingerprint = useSelector((s) => s[props.FINGERPRINT])
  const addStampToCard = functional.use(Routes.ADD_STAMP)
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    const addStamp = async () => {
      const addedStampStatus = await addStampToCard({ businessUUID, fingerprintUUID: fingerprint.uuid })
      const url = `/visit/${businessUUID}?addedStamp=${!!addedStampStatus}`
      history.replace(url)
      navigate(url)
    }
    if (fingerprint && processing === false) {
      setProcessing(true)
      addStamp()
    }
  }, [fingerprint, businessUUID, addStampToCard, navigate, processing])

  return (
    <Layout authLevelRequired={false}>
      <Container className="py-10">
        <Loading loading={false} />
      </Container>
    </Layout>
  )
}

export default AddStamp
