import Business from '../../../types/Business'
import Meta from './Meta'

type Props = {
  business: Business
}

const Tracking: React.FC<Props> = ({ business }) => {
  return (
    <>
      <Meta business={business} />
    </>
  )
}

export default Tracking
