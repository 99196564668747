import { useSelector } from 'react-redux'
import Features from '../../../../../redux/features'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import User from '../../../../../types/User'
import Routes from '../../../../../redux/routes'

type Props = {
  data: User
  dataCallback: (data: any) => void
}

const LockSwitch: React.FC<Props> = ({ data, dataCallback }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const toggleKnownUserLock = functional.use(Routes.TOGGLE_KNOWN_USER_LOCK)

  const toggle = async () => {
    const result = await toggleKnownUserLock({ userUUID: data.uuid })
    if (result) {
      dataCallback({ ...data, isLocked: !data.isLocked })
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.known.lock.error)
    }
  }

  return (
    <label className={`swap ml-1 btn ${data.isLocked ? 'btn-error' : 'btn-success'}`}>
      <input type="checkbox" onChange={toggle} checked={!data.isLocked} />
      <div className="swap-on">{translation.dashboard.user.known.lock.off}</div>
      <div className="swap-off">{translation.dashboard.user.known.lock.on}</div>
    </label>
  )
}

export default LockSwitch
