import Business from '../../types/Business'
import { getBusinessName } from '../../utility'
import Avatar from '../UI/Avatar/Avatar'
import CategoryLabel from '../UI/Category/Label/Label'
import Location from '../UI/StampCard/BusinessPreview/Location'

type Props = {
  business: Business
}

const Header: React.FC<Props> = ({ business }) => {
  return (
    <div className="bg-gray-100 rounded-2xl p-2 pr-8 flex gap-3 box-border max-w-sm mx-auto">
      <Avatar className="w-1/5 bg-transparent flex-shrink-0" url={business.logoURL} />
      <div className="flex flex-col gap-1 justify-center w-4/5">
        <CategoryLabel className="!rounded-full py-[1px]" category={business.category} />
        <div className="truncate font-bold">{getBusinessName(business)}</div>
        <Location business={business} />
      </div>
    </div>
  )
}

export default Header
