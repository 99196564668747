import { useSelector } from 'react-redux'
import Features from '../../../redux/features'
import Business from '../../../types/Business'

type Props = {
  business: Business
}

const Label: React.FC<Props> = ({ business }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  if (!business.bounty && !business.bountyEmoji) {
    return <div className="font-bold">{translation.UI.bounty.label.fallback}</div>
  }
  return (
    <span className="inline-flex items-center gap-1">
      {business.bounty && <div className="font-bold">{business.bounty}</div>}
      {business.bountyEmoji && <div className="text-xl">{business.bountyEmoji}</div>}
    </span>
  )
}

export default Label
