import { functional } from '@think-internet/zeus-frontend-package'
import { useEffect, useState } from 'react'
import Routes from '../../../../redux/routes'
import toast, { TYPE } from '../../../../toast'
import { useSelector } from 'react-redux'
import Features from '../../../../redux/features'
import props from '../../../../redux/props'
import Stamp from '../../../../types/Stamp'
import Redeem from '../../../../types/Redeem'
import { getPrettyDate } from '../../../../utility'
import Loading from '../../Loading/Loading'
import plusOne from '../../../../assets/image/stampcard/plus-one.png'
import reward from '../../../../assets/image/stampcard/reward.png'
import Fingerprint from '../../../../types/Fingerprint'

type Props = {
  businessUUID: string
  className?: string
}

const History: React.FC<Props> = ({ businessUUID, className = '' }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const fingerprint: Fingerprint = useSelector((s) => s[props.FINGERPRINT])
  const [history, setHistory] = useState<Array<Stamp | Redeem> | false>(false)
  const getHistory = functional.use(Routes.GET_STAMP_CARD_HISTORY_FOR_USER)

  useEffect(() => {
    const get = async () => {
      const history = await getHistory({ fingerprintUUID: fingerprint.uuid, businessUUID })
      if (history) {
        setHistory(history)
      } else {
        toast(TYPE.ERROR, translation.stampCard.history.error)
      }
    }
    if (!history && fingerprint) {
      get()
    }
  })

  const isStamp = (item: Stamp | Redeem): boolean => {
    return (item as Redeem).stampUUIDList === undefined
  }

  const getText = (item: Stamp | Redeem): string => {
    return isStamp(item) ? translation.stampCard.history.added.stamp : translation.stampCard.history.added.redeem
  }

  const getIcon = (item: Stamp | Redeem): string => {
    return isStamp(item) ? plusOne : reward
  }

  return (
    <div className={`mt-10 ${className}`}>
      <Loading loading={history} />
      {Array.isArray(history) && history.length > 0 && (
        <>
          <div className="text-xl font-bold">{translation.stampCard.history.headline}</div>
          <div className="text-secondary">{translation.stampCard.history.subheadline}</div>
          <div className="mt-3 max-w-sm max-h-[30vh] overflow-y-auto flex flex-col gap-2">
            {history.map((item) => (
              <div key={item.uuid} className="bg-gray-100 rounded-2xl p-2 pr-6 flex gap-1 box-border max-w-sm w-full min-h-16">
                <div className="w-2/12 flex-shrink-0 flex items-center justify-center">
                  <img className={isStamp(item) ? 'w-7 max-h-full' : 'w-10 max-h-full'} src={getIcon(item)} alt={getText(item)} />
                </div>
                <div className="flex flex-row gap-1 justify-between items-center w-10/12">
                  <div className="truncate text-sm">{getText(item)}</div>
                  <p className="truncate text-xs text-gray-500">{getPrettyDate(new Date(item.createdOn))}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default History
