import styled from 'styled-components'

export const StyledHeader = styled.div<{ transparent }>`
  position: fixed;
  width: 100vw;
  height: ${({ theme }) => theme.headerHeight};
  z-index: 9;
  top: 0;
  ${({ transparent }) =>
    !transparent &&
    `
  background: rgb(251, 216, 125);
  background: linear-gradient(90deg, rgba(251, 216, 125, 1) 0%, rgba(252, 195, 196, 1) 100%);
  `}
`

export const Toggle = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.font.secondary};
  .icon {
    font-size: 30px;
  }
`

export const Navigation = styled.div`
  background-color: ${({ theme }) => theme.color.background.primary};
  position: absolute;
  width: 100vw;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  margin-top: ${({ theme }) => theme.headerHeight};
  top: 0px;
  left: 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 25px 0px;
  }
`

export const Logo = styled.img`
  cursor: pointer;
  height: ${({ theme }) => `calc(${theme.headerHeight} - 20px)`};
`
