import Container from '../../../UI/Container/Container'
import { StyledContent } from './Content.Styled'

const Content = ({ children }) => {
  return (
    <StyledContent>
      <Container>{children}</Container>
    </StyledContent>
  )
}

export default Content
