import { useState } from 'react'
import Features from '../../../../redux/features'
import { useSelector } from 'react-redux'
import Modal, { TYPE as ModalType } from '../../../UI/Modal/Modal'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../toast'
import BusinessCategory from '../../../../types/BusinessCategory'

type Props = {
  children: React.ReactNode | string
  removedCallback: () => void
  category: BusinessCategory
}

const Remove: React.FC<Props> = ({ category, children, removedCallback }) => {
  const [show, setShow] = useState(false)
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const categoryFeature = crud.use(Features.BUSINESS_CATEGORY)

  const remove = async () => {
    const result = await categoryFeature.remove({ uuid: category.uuid })
    if (result) {
      removedCallback()
      setShow(false)
    } else {
      toast(TYPE.ERROR, translation.dashboard.businessCategory.removeError)
    }
  }

  return (
    <>
      <div onClick={() => setShow(true)}>{children}</div>
      <Modal
        type={ModalType.WARNING}
        show={show}
        onClose={() => setShow(false)}
        onConfirm={remove}
        title={`"${category.name}"${translation.dashboard.businessCategory.remove.modalTitleSuffix}`}
        text={translation.dashboard.businessCategory.remove.message}
      />
    </>
  )
}

export default Remove
