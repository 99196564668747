import { getBusinessName } from '../../../../utility'
import StampCard from '../../../../types/StampCard'
import partyImage from '../../../../assets/image/stampcard/party.png'
import { useSelector } from 'react-redux'
import Features from '../../../../redux/features'
import BountyLabel from '../../../UI/Bounty/Label'

type Props = {
  stampCard?: StampCard
  children?: React.ReactNode
}

const IsFull: React.FC<Props> = ({ stampCard, children }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  return (
    <>
      <div>
        <div className="text-center mt-5">
          <img className="h-24 inline" src={partyImage} alt={translation.stampCard.full.title} />
        </div>
        <div className="text-2xl text-center mt-3 uppercase">{translation.stampCard.full.title}</div>
        <div className="text-sm mt-3 items-center w-full text-center">
          {translation.stampCard.full.bounty.first}
          <span className="font-bold">
            {translation.stampCard.full.bounty.second}
            {getBusinessName(stampCard.business)}
            {translation.stampCard.full.bounty.third}
          </span>
        </div>
        <div className="text-sm mt-3 flex items-center w-full justify-center gap-1 flex-wrap mb-5">
          {translation.stampCard.full.points.prefix}
          <BountyLabel business={stampCard.business} />
          {translation.stampCard.full.points.suffix}
        </div>
      </div>
      {children}
    </>
  )
}

export default IsFull
