import { useSelector } from 'react-redux'
import Features from '../../../../redux/features'
import { ReactNode, useState } from 'react'
import Input from '../../../UI/Input/Input'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../toast'
import Modal from '../../../UI/Modal/Modal'
import Loading from '../../../UI/Loading/Loading'
import BusinessCategory from '../../../../types/BusinessCategory'
import ColorPicker from '../../../UI/ColorPicker/ColorPicker'

type Props = {
  category?: BusinessCategory
  callback: (data: BusinessCategory) => void
  children: ReactNode
}

const Upsert: React.FC<Props> = ({ category, children, callback }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const [data, setData] = useState<BusinessCategory>(category || {})
  const businessCategoryFeature = crud.use(Features.BUSINESS_CATEGORY)
  const [show, setShow] = useState(false)

  const set = (key: string) => (value: any) => {
    setData({ ...data, [key]: value })
  }

  const save = async () => {
    const result = await businessCategoryFeature.upsert(data)
    if (!!result) {
      toast(TYPE.SUCCESS, translation.dashboard.businessCategory.upsert.success)
      setShow(false)
      setData({})
      callback(result)
    } else {
      toast(TYPE.ERROR, translation.dashboard.businessCategory.upsert.error)
    }
  }

  return (
    <>
      <div onClick={() => setShow(true)}>{children}</div>
      <Modal
        confirmButtonText={translation.generic.save}
        show={show}
        onClose={() => setShow(false)}
        onConfirm={save}
        title={data === null ? '' : data?.name || (category ? '' : translation.dashboard.businessCategory.upsert.headline)}
      >
        {!data && <Loading loading={data} />}
        {data && (
          <>
            <Input required label={translation.dashboard.businessCategory.upsert.name} onChange={set('name')} value={data.name} autoComplete="off" />
            <ColorPicker label={translation.dashboard.businessCategory.upsert.colorPickerLabel} currentColor={data.color} onSelect={set('color')} />
          </>
        )}
      </Modal>
    </>
  )
}

export default Upsert
