import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import Login from './components/Login/Login'
import Register from './components/Register/Register'
import Confirm from './components/Confirm/Confirm'
import ForgotPassword from './components/ForgotPassword/ForgotPassword'
import SetNewPassword from './components/SetNewPassword/SetNewPassword'
import Dashboard from './components/Dashboard/Dashboard'
import Home from './components/Home/Home'
import AddStamp from './components/AddStamp/AddStamp'
import Visit from './components/Visit/Visit'
import RedeemNow from './components/Visit/Redeem/Now/Now'
import RedeemLater from './components/Visit/Redeem/Later/Later'
import ConfirmNewMail from './components/ConfirmNewMail/ConfirmNewMail'
import './global.scss'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Login />} />
            <Route path={'login'} element={<Login />} />
            <Route path={'register'} element={<Register />} />
            <Route path={'confirm'} element={<Confirm />} />
            <Route path={'confirm/:accountLinkUUID'} element={<Confirm />} />
            <Route path={'forgot-password'} element={<ForgotPassword />} />
            <Route path={'reset/:resettoken'} element={<SetNewPassword />} />
            <Route path={'confirm-new-mail/:uuid'} element={<ConfirmNewMail />} />
            <Route path={'dashboard/*'} element={<Dashboard />} />
            <Route path={'home/*'} element={<Home />} />
            <Route path={'add-stamp/:businessUUID'} element={<AddStamp />} />
            <Route path={'visit/:businessUUID'} element={<Visit />} />
            <Route path={'visit/redeem/:businessUUID'} element={<RedeemNow />} />
            <Route path={'visit/redeem-later/:businessUUID'} element={<RedeemLater />} />
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
