import BusinessCategory from '../../../../types/BusinessCategory'
import Upsert from '../Upsert/Upsert'
import Button from '../../../UI/Button/Button'
import { TrashIcon } from '@heroicons/react/24/outline'
import Remove from './Remove'
import { useSelector } from 'react-redux'
import Features from '../../../../redux/features'
import { Color } from './Color.Styled'

type Props = {
  category: BusinessCategory
  updateCallback: (category: BusinessCategory) => void
  removeCallback: () => void
}

const Item: React.FC<Props> = ({ category, updateCallback, removeCallback }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  return (
    <li className="flex justify-between gap-x-6 py-2 items-center">
      <Upsert category={category} callback={updateCallback}>
        <div className="cursor-pointer font-semibold leading-6 text-gray-900 hover:underline">{category.name}</div>
        {category.color && (
          <div className="flex items-center gap-1 text-sm">
            {translation.dashboard.businessCategory.color}
            <Color className="inline-block rounded-sm h-5 w-10" color={category.color} />
          </div>
        )}
      </Upsert>
      <Remove removedCallback={removeCallback} category={category}>
        <Button className="btn-sm btn-error">
          <TrashIcon className="w-5 h-5" />
        </Button>
      </Remove>
    </li>
  )
}

export default Item
