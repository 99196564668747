import Offline from './Offline'
import IsFull from './IsFull/IsFull'
import IsNotFull from '../../Visit/Context/IsNotFull/IsNotFull'
import { Wrapper } from '../../UI/StampCard/StampCard'
import { isStampCardFull } from '../../UI/StampCard/Card/helper/isCurrentCardFull'

const Context: Wrapper = ({ stampCard, hasAddedStamp, children }) => {
  const isFull = isStampCardFull(stampCard)

  return (
    <div className={'flex justify-center relative'}>
      <div className="w-full box-border">
        {stampCard && (
          <div>
            {!stampCard.business.online ? (
              <Offline />
            ) : (
              <>
                {isFull && <IsFull stampCard={stampCard}>{children}</IsFull>}
                {!isFull && (
                  <IsNotFull stampCard={stampCard} hasAddedStamp={hasAddedStamp}>
                    {children}
                  </IsNotFull>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Context
