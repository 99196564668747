import { useSelector } from 'react-redux'
import Fingerprint from '../../../../types/Fingerprint'
import Divider from '../../../UI/Divider/Divider'
import Features from '../../../../redux/features'
import props from '../../../../redux/props'
import SendCard from './SendCard'

const RedeemLaterHelp = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const fingerprint: Fingerprint = useSelector((s) => s[props.FINGERPRINT])

  const getHint = () => {
    if (fingerprint.isKnown) {
      return translation.stampCard.redeemLater.hint.textKnownUser
    }
    return translation.stampCard.redeemLater.hint.textUnknownUser
  }

  if (!fingerprint) return null
  return (
    <div>
      <Divider text={translation.stampCard.redeemLater.hint.divide} className="my-5" />
      <div className="text-center text-sm">{getHint()}</div>
      {!fingerprint.isKnown && <SendCard />}
    </div>
  )
}

export default RedeemLaterHelp
