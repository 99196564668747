import Input from '../UI/Input/Input'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../toast'
import features from '../../redux/features'
import Form from '../UI/Form/Form'
import Container from '../UI/Container/Container'
import Button from '../UI/Button/Button'
import Routes from '../../redux/routes'
import Link from '../UI/Link/Link'
import { EnvelopeIcon } from '@heroicons/react/24/solid'

const ForgotPassword = () => {
  const translation = useSelector((s) => s[features.TRANSLATION])
  const [identifier, setIdentifier] = useState('')
  const resetPassword = functional.use(Routes.RESET_PASSWORD)
  const [status, setStatus] = useState(false)

  const submit = async () => {
    const status = await resetPassword({ identifier })
    if (status) {
      setStatus(true)
    } else {
      toast(TYPE.ERROR, translation.forgotPassword.error)
    }
  }

  return (
    <Layout authLevelRequired={false}>
      <Container className="px-10 py-3">
        <div className="mt-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-7 text-2xl font-bold leading-9">{translation.forgotPassword.headline}</h2>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            {!status && (
              <Form className="space-y-4" onSubmit={submit}>
                <Input
                  label={translation.generic.mail}
                  placeholder={translation.generic.mail}
                  onChange={setIdentifier}
                  value={identifier}
                  required
                  type="email"
                  autoComplete="email"
                  Icon={EnvelopeIcon}
                />
                <Button type="submit" className="w-full" text={translation.forgotPassword.cta} />
              </Form>
            )}
            {status && <div className="text-center mt-5 sm:mx-auto sm:w-full sm:max-w-sm">{translation.forgotPassword.success}</div>}

            <p className="mt-5 text-center text-sm">
              <Link href="/login" className="leading-6 underline">
                {translation.forgotPassword.backToLogin}
              </Link>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ForgotPassword
