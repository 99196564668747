import StampCard from '../../../../../types/StampCard'
import getFullCardsAmount from './getFullCardsAmount'

const getCurrentCard = (stampCard: StampCard): StampCard => {
  const fullCards = getFullCardsAmount(stampCard)
  const currentCardStamps = stampCard.stampCount - fullCards * stampCard.limits.stampsPerCard

  return { ...stampCard, stampCount: currentCardStamps }
}

export default getCurrentCard
