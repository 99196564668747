import React from 'react'

export interface IForm {
  onSubmit: () => void
  children: React.ReactNode
  className?: string
}

const Form = ({ onSubmit = () => {}, children, className }: IForm) => {
  const submit = (e) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <form onSubmit={submit} className={className}>
      {children}
    </form>
  )
}

export default Form
