import { useSelector } from 'react-redux'
import Features from '../../../../../redux/features'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import Business from '../../../../../types/Business'

type Props = {
  data: Business
  dataCallback: (data: any) => void
}

const OnlineSwitch: React.FC<Props> = ({ data, dataCallback }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const businessFeature = crud.use(Features.BUSINESS)

  const toggle = async () => {
    const newState = !data.online
    const result = await businessFeature.update({ uuid: data.uuid, online: newState })
    if (!!result) {
      dataCallback({ ...data, online: newState })
    } else {
      toast(TYPE.ERROR, translation.dashboard.business.view.onlineSwitch.error)
    }
  }

  return (
    <label className={`swap ml-1 btn ${data.online ? 'btn-success' : 'btn-error'}`}>
      <input type="checkbox" onChange={toggle} checked={data.online} />
      <div className="swap-on">{translation.dashboard.business.view.onlineSwitch.online}</div>
      <div className="swap-off">{translation.dashboard.business.view.onlineSwitch.offline}</div>
    </label>
  )
}

export default OnlineSwitch
