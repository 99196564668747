import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, QuestionMarkCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import Features from '../../../redux/features'
import { useSelector } from 'react-redux'
import Button from '../Button/Button'
import Form from '../Form/Form'

export enum TYPE {
  WARNING = 'warning',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

type Props = {
  type?: TYPE
  show: boolean
  onClose: () => void
  title?: string
  children?: React.ReactNode
  text?: string
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm?: () => void
}

const Modal: React.FC<Props> = ({ type, show, onClose, title, children, text, confirmButtonText, cancelButtonText, onConfirm }) => {
  const translation = useSelector((s) => s[Features.TRANSLATION])

  const getIcon = () => {
    if (!type) return null
    switch (type) {
      case TYPE.WARNING:
        return (
          <div className="sm:mr-4 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
        )
      case TYPE.INFO:
        return (
          <div className="sm:mr-4 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
            <QuestionMarkCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </div>
        )
      case TYPE.SUCCESS:
        return (
          <div className="sm:mr-4 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
            <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
        )
      default:
        return null
    }
  }

  const getConfirmButtonStyle = () => {
    if (!type) return 'btn-primary'
    switch (type) {
      case TYPE.WARNING:
        return 'btn-error'
      default:
        return 'btn-primary'
    }
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10 box-border" onClose={onClose}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl w-full transition-all sm:my-8 sm:max-w-lg">
                <Form onSubmit={onConfirm}>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className={!!type ? 'sm:flex sm:items-start' : ''}>
                      {getIcon()}
                      <div className="text-center sm:mt-0 sm:text-left">
                        {!!title && (
                          <Dialog.Title as="h3" className="mb-2 text-base font-semibold leading-6 text-gray-900">
                            {title}
                          </Dialog.Title>
                        )}
                        <div>
                          {!!text && <p className="text-sm text-gray-500">{text}</p>}
                          {!!children && children}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between sm:justify-end gap-1 bg-gray-50 px-4 py-3 sm:px-6">
                    <Button className="btn btn-ghost btn-sm" onClick={onClose} text={cancelButtonText || translation.generic.cancel} />
                    {!!onConfirm && <Button type="submit" className={`btn-sm ${getConfirmButtonStyle()}`} text={confirmButtonText || translation.generic.confirm} />}
                  </div>
                </Form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
