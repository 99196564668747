type Props = {
  text: string
  className?: string
}

const Divider: React.FC<Props> = ({ text, className = '' }) => {
  return <div className={`divider before:h-[1px] before:bg-gray-600 after:h-[1px] after:bg-gray-600 text-sm ${className}`}>{text}</div>
}

export default Divider
