import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  /* font-size: ${({ theme }) => theme.font.size.md}; */
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.color.background.primary};
}

.Toastify {
  * {
  font-size: ${({ theme }) => theme.font.size.sm};
  }
}

`

export default GlobalStyles
