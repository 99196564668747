import { useNavigate } from 'react-router-dom'
import StampCard from '../../../../types/StampCard'
import Avatar from '../../Avatar/Avatar'
import { getBusinessName, getPrettyDate, getStampCardAmount, getStampRelation } from '../../../../utility'
import { useSelector } from 'react-redux'
import Features from '../../../../redux/features'
import { ClockIcon } from '@heroicons/react/24/solid'
import CategoryLabel from '../../Category/Label/Label'
import Location from './Location'

type Props = {
  stampCard: StampCard
  className?: string
}

const BusinessPreview: React.FC<Props> = ({ stampCard, className }) => {
  const navigate = useNavigate()
  const translation = useSelector((s) => s[Features.TRANSLATION])

  const go = (uuid: string) => () => {
    navigate('/home/stampcards/' + uuid)
  }

  return (
    <div onClick={go(stampCard.business.uuid)} className={`bg-gray-100 rounded-2xl px-3 py-2 flex items-center ${className}`}>
      <div className="!w-3/12 flex-shrink-0 flex items-center cursor-pointer">
        <Avatar className="w-full" url={stampCard.business.logoURL} />
      </div>
      <div className="flex flex-col justify-around w-9/12 flex-shrink-0 pl-3 relative">
        <div className="absolute right-0 top-1 text-xs font-bold">{`${getStampRelation(stampCard)}${getStampCardAmount(stampCard) > 1 ? ` (${getStampCardAmount(stampCard)})` : ''}`}</div>
        <div className="mb-1 truncate cursor-pointer max-w-[85%]">{getBusinessName(stampCard.business)}</div>
        {stampCard.lastUsed && (
          <div className="truncate max-w-full text-xs flex gap-1  text-[#656565]">
            <ClockIcon className="w-4 flex-shrink-0" />
            {translation.stampCard.businessPreview.lastVisit}
            <div className="truncate">{getPrettyDate(new Date(stampCard.lastUsed))}</div>
          </div>
        )}
        <Location business={stampCard.business} />
        <CategoryLabel className="mt-2 !rounded-full py-[1px]" category={stampCard.business.category} />
      </div>
    </div>
  )
}

export default BusinessPreview
