import { useSelector } from 'react-redux'
import Content from '../Components/Content'
import Features from '../../../redux/features'
import { useEffect, useState } from 'react'
import { crud, functional } from '@think-internet/zeus-frontend-package'
import { useParams } from 'react-router-dom'
import toast, { TYPE } from '../../../toast'
import Loading from '../../UI/Loading/Loading'
import Avatar from '../../UI/Avatar/Avatar'
import User from '../../../types/User'
import FileSelect from '../../UI/FileSelect/FileSelect'
import placeholder from '../../../assets/image/profile/placeholder.png'
import Progress from '../../UI/Progress/Progress'
import Routes from '../../../redux/routes'
import { s3Upload } from '@think-internet/zeus-frontend-package/fetch/fetch'
import Birthday from './Birthday'
import Phone from './Phone'
import Mail from './Mail'
import Password from './Password'
import { EnvelopeIcon } from '@heroicons/react/24/solid'
import Layout from '../../Layout/Layout'
import { Level } from '../../../types/global'
import Logout from './Logout'

const Profile = () => {
  const translation = useSelector((s) => s[Features.TRANSLATION])
  const token = useSelector((s) => s[Features.TOKEN])
  const [user, setUser] = useState<User>(null)
  const userFeature = crud.use(Features.USER)
  const updateUser = functional.use(Routes.UPDATE_USER)
  const getPresignedUploadURL = functional.use(Routes.GET_PRESIGNED_UPLOAD_URL)
  const { uuid } = useParams()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const get = async () => {
      const user = await userFeature.get({})

      if (user) {
        setUser(user)
      } else {
        toast(TYPE.ERROR, translation.generic.error.loading)
      }
    }
    if (!user && token) {
      get()
    }
  }, [user, userFeature, uuid, translation, token])

  const getName = () => `${user.firstname} ${user.lastname}`

  const update = async (data: User) => {
    const result = await updateUser({ data })
    if (!!result) {
      toast(TYPE.SUCCESS, translation.home.profile.update.success)
      setUser(result)
    } else {
      toast(TYPE.ERROR, translation.home.profile.update.error)
    }
  }

  const processImage = async (file: File) => {
    if (!file) return false
    const { presignedURL, key } = await getPresignedUploadURL({ type: 'PROFILE_IMAGE', meta: { type: file.type, name: file.name, size: file.size } })
    const uploadResult = await s3Upload(presignedURL, file, setProgress)
    if (!uploadResult) return false
    setProgress(0)
    update({ ...user, imageKey: key })
  }

  const setProp = (prop) => (value) => {
    update({ ...user, [prop]: value })
  }

  return (
    <Layout authLevelRequired={[Level.DEFAULT]}>
      {!!user && (
        <>
          {/* <Gradient className="pt-5 pb-10"> */}
          <div className="pt-5 pb-10">
            <div className="flex justify-center">
              <Progress radial progress={progress} />
            </div>
            {progress === 0 && (
              <FileSelect acceptedMimeTypes={['image/*']} label={translation.home.profile.selectImage} onFile={processImage}>
                <div className="text-center cursor-pointer">
                  <Avatar className="w-40" url={user.imageURL || placeholder} alt={getName()} />
                </div>
              </FileSelect>
            )}
          </div>
          <div className="text-xl font-bold text-center uppercase mt-2">{getName()}</div>
          <div className="w-full flex flex-row gap-2 cursor-pointer text-sm justify-center">
            <EnvelopeIcon className="w-5" />
            {user.account.identifier}
          </div>
          {/* </Gradient> */}
          <Content className="pb-6">
            <div className="font-headline text-2xl mt-10">{translation.home.profile.settings}</div>
            <div className="font-headline text-sm text-secondary my-3">{translation.home.profile.profile}</div>
            <div className="flex flex-col gap-2 mt-2">
              <Birthday user={user} set={setProp('birthday')} />
              <Phone user={user} set={setProp('phone')} />
              <Mail user={user} />
            </div>
            <div className="font-headline text-sm text-secondary my-3">{translation.home.profile.security}</div>
            <Password user={user} />
            <div className="font-headline text-sm text-secondary my-3">{translation.home.profile.other}</div>
            <Logout />
          </Content>
        </>
      )}
      {!user && <Loading loading={user} />}
    </Layout>
  )
}

export default Profile
