const Routes = {
  REGISTER: 'REGISTER',
  CONFIRM: 'CONFIRM',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  GET_FINGERPRINT: 'GET_FINGERPRINT',
  GET_PRESIGNED_UPLOAD_URL: 'GET_PRESIGNED_UPLOAD_URL',
  GET_LOCATION_SUGGESTION: 'GET_LOCATION_SUGGESTION',
  GET_GOOGLE_PLACE_COORDINATES: 'GET_GOOGLE_PLACE_COORDINATES',
  ADD_STAMP: 'ADD_STAMP',
  GET_STAMP_CARD: 'GET_STAMP_CARD',
  REDEEM: 'REDEEM',
  GET_BUSINESS_STATISTICS: 'GET_BUSINESS_STATISTICS',
  GET_USER_STATISTICS: 'GET_USER_STATISTICS',
  GET_FINGERPRINT_STATISTICS: 'GET_FINGERPRINT_STATISTICS',
  GET_ANONYMOUS_USERS: 'GET_ANONYMOUS_USERS',
  REQUEST_NEW_MAIL: 'REQUEST_NEW_MAIL',
  CONFIRM_NEW_MAIL: 'CONFIRM_NEW_MAIL',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  GET_VISITED_BUSINESS_LIST: 'GET_VISITED_BUSINESS_LIST',
  GET_STAMP_CARD_HISTORY_FOR_USER: 'GET_STAMP_CARD_HISTORY_FOR_USER',
  GET_STAMP_CARD_HISTORY: 'GET_STAMP_CARD_HISTORY',
  TOGGLE_KNOWN_USER_LOCK: 'TOGGLE_KNOWN_USER_LOCK',
  TOGGLE_FINGERPRINT_LOCK: 'TOGGLE_FINGERPRINT_LOCK',
  REMOVE_KNOWN_USER: 'REMOVE_KNOWN_USER',
  REMOVE_ANONYMOUS_USER: 'REMOVE_ANONYMOUS_USER',
  SEND_CARD_MAIL_FOR_ANONYMOUS_USER: 'SEND_CARD_MAIL_FOR_ANONYMOUS_USER',
  UPDATE_USER: 'UPDATE_USER',
}

export default Routes
