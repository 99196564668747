import { useParams } from 'react-router-dom'
import Layout from '../Layout/Layout'
import { useEffect, useState } from 'react'
import Loading from '../UI/Loading/Loading'
import { crud } from '@think-internet/zeus-frontend-package'
import Features from '../../redux/features'
import Header from './Header'
import toast, { TYPE } from '../../toast'
import { useSelector } from 'react-redux'
import Context from './Context/Context'
import LoginOrRegister from '../UI/LoginOrRegister/LoginOrRegister'
import Tracking from './Tracking/Tracking'
import StampCard from '../UI/StampCard/StampCard'

const Visit = () => {
  const { businessUUID } = useParams()
  const [business, setBusiness] = useState(null)
  const businessFeature = crud.use(Features.BUSINESS)
  const translation = useSelector((s) => s[Features.TRANSLATION])

  useEffect(() => {
    const getBusiness = async () => {
      const business = await businessFeature.get({ uuid: businessUUID })

      if (business) {
        setBusiness(business)
      } else {
        toast(TYPE.ERROR, translation.visit.error)
      }
    }

    if (!business) {
      getBusiness()
    }
  }, [businessUUID, business, businessFeature, translation])

  return (
    <Layout authLevelRequired={false}>
      <div className="container px-5 pb-10 pt-5">
        <Loading loading={business} />
        {business && <Tracking business={business} />}
        {business && <Header business={business} />}
        {business && (
          <div className="max-w-sm sm:p-5 mx-auto">
            <StampCard businessUUID={businessUUID} Wrapper={Context} />
          </div>
        )}
        <LoginOrRegister />
      </div>
    </Layout>
  )
}

export default Visit
