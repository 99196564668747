type Props = {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

const Toggle: React.FC<Props> = ({ checked, onChange, disabled = false }) => {
  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked)
  }

  return <input type="checkbox" disabled={disabled} className="toggle toggle-secondary" onChange={change} checked={checked} />
}

export default Toggle
